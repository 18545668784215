import { createContext, useEffect, useRef, useState } from "react";
import { $whoami } from "api";
import { $refresh } from "api/refresh";
import { $login, $logout } from "api/login";
import { MgSpinner } from "components/base/MgSpinner";
import config from "mg.config";
import { useHistory } from "react-router-dom";
import moment from "moment";

const getCookieValue = (name) => (
  document.cookie.match('(^|;)\\s*' + name + '\\s*=\\s*([^;]+)')?.pop() || ''
)

const initialState = {
  isAuthenticated: false,
  user: null,
  permissions: [],
};

const AuthContext = createContext(initialState);

const AuthProvider = ({ children }) => {

  const [ready, setReady] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [user, setUser] = useState(null);
  const [env, setEnv] = useState(null);
  const [userMid, setUserMid] = useState(null);
  const [merchant, setMerchant] = useState(null);
  const [features, setFeatures] = useState(null);
  const [permissions, setPermissions] = useState(null);
  const [enableRegionScoping, setEnableRegionScoping] = useState(false);
  const [scopedRegionIds, setScopedRegionIds] = useState([]);
  const refreshing = useRef(false);
  const history = useHistory();

  useEffect(() => void beginOrContinueSession(), []);

  useEffect(() => {
    setIsAuthenticated(ready === true && user !== null);
  }, [user, ready]);

  const setData = (
    {
      env,
      user,
      mid,
      merchant,
      features,
      permissions,
      enableRegionScoping,
      scopedRegionIds
    }
  ) => {
    permissions = permissions || user.permissions;
    setUser(user);
    setUserMid(mid);
    setMerchant(merchant);
    setFeatures(features);
    setPermissions(permissions);
    setEnv(env);
    setEnableRegionScoping(!!enableRegionScoping);
    setScopedRegionIds(scopedRegionIds || []);
  }

  const clearData = () => {
    setIsAuthenticated(false);
    setUser(null);
    setUserMid(null);
    setPermissions(null);
  }

  const reloadData = async (redirect = null) => {
    const res = await $whoami();
    if (res.ok) {
      setData(res.data);
      setReady(true);
      setError(null);
    }
    if (redirect) {
      history.push(redirect)
    }
  }

  const beginOrContinueSession = async () => {

    if (loading) {
      return;
    }

    // check for existing user in localStorage.
    const tokens = loadTokens();
    if (!tokens) {
      clearData();
      setReady(true);
      return;
    }

    // check if existing session is still effective.
    setLoading(true);
    const res = await $whoami();
    // setLoading(false);
    if (res.ok) {
      // it is.
      setData(res.data);
      setReady(true);
      setError(null);
    } else if (res.error?.statusCode === 401) {
      // try to refresh - if unsuccessful- redirect to login page.
      await refresh(beginOrContinueSession, () => setReady(true));
    } else {
      setReady(true);
      setError(res.error);
    }
    setLoading(false);
  }


  const refresh = async (onSuccess, onFail) => {

    if (refreshing.current) {
      return true;
    }

    const _fail = async (error) => {
      clearData();
      // clearTokens();
      refreshing.current = false;
      setLoading(false);
      if (error) {
        setError(error);
      }
      if (typeof onFail === "function") {
        await onFail();
      }
    }

    const tokens = loadTokens();

    refreshing.current = true;
    setError(null);
    const res = await $refresh(tokens.refreshToken);
    refreshing.current = false;
    if (res.ok) {
      // use existing refresh token for subsequent checks
      if (!res.data.refreshToken) {
        res.data.refreshToken = tokens.refreshToken
      }
      storeTokens(res.data);
      if (typeof onSuccess === "function") {
        await onSuccess();
      }
      return true;
    } else {
      await _fail(res.error);
    }
    return false;
  }

  const storeTokens = ({ accessToken, refreshToken, claims }) =>
    localStorage.setItem(config.authStorageName, JSON.stringify({ accessToken, refreshToken, claims }));

  const clearTokens = () => {
    localStorage.removeItem(config.authStorageName);
  }

  const authenticate = async ({ token, username, password, otpCode }) => {
    setLoading(true);
    setError(null);
    const res = await $login({ token, username, password, otpCode });
    if (res.ok) {
      if (!!res.data.otpRequired) {
        setLoading(false);
        return res.data
      } else {
        storeTokens(res.data);
        return await beginOrContinueSession()
      }
    } else {
      setError(res.error);
      if (otpCode && !!otpCode?.length) {
        setLoading(false);
        return res
      }
    }
    setLoading(false);
  }

  const logout = () => {
    setIsAuthenticated(false);
    clearData();
    clearTokens();
    // recentItems.clearItems();
    void $logout();
  }

  const loadTokens = () => {
    const tokens = localStorage.getItem(config.authStorageName);
    if (!!!tokens) {
      return null;
    }
    try {
      return JSON.parse(tokens);
    } catch (err) {
      return null;
    }
  }

  const needToRefresh = () => {
    if (!isAuthenticated) {
      return false;
    }
    const claims = loadTokens()?.claims || null;
    if (!claims) {
      return false;
    }
    const { expiresAt } = claims;
    if (!expiresAt) {
      return false;
    }
    const diff = moment().diff(moment(expiresAt), "second");
    return diff >= -60;
  }

  const refreshIfNeeded = async () => {
    if (refreshing.current) {
      return;
    } else if (!needToRefresh()) {
      return;
    }
    await refresh();
  }

  function getCsrfToken() {
    return getCookieValue("csrftoken");
  }

  const claims = loadTokens()?.claims || null;
  const hasClaims = claims !== null;
  const baseCurrency = merchant?.baseCurrency;

  return <AuthContext.Provider value={
    {
      //
      loading,
      refreshing,
      error,
      env,
      isAuthenticated,
      user,
      userMid,
      merchant,
      features,
      baseCurrency,
      permissions,
      hasClaims,
      claims,
      enableRegionScoping,
      scopedRegionIds,
      //
      authenticate,
      refresh,
      getCsrfToken,
      refreshIfNeeded,
      logout,
      reloadData,
      //
    }
  }>

    { !ready &&
      <div className="flex flex-col items-center justify-center min-h-screen">
        <MgSpinner size={ 26 } />
      </div> }

    { ready && children }

  </AuthContext.Provider>
}

export { AuthContext, AuthProvider };