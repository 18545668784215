import { useEffect, useState } from 'react'
import { Switch } from '@headlessui/react'
import { classNames } from "util/classUtil";
import config from "mg.config";


export function MgSwitch(
  {
    value = false,
    onChange = (val) => {},
    disabled = false,
  }
) {
  const [enabled, setEnabled] = useState(false);
  useEffect(() => setEnabled(value), [value]);

  const onSwitchChange = (val) => {
    setEnabled(val)
    onChange(val);
  }

  return (
    <Switch
      disabled={ disabled }
      checked={ enabled }
      onChange={ onSwitchChange }
      className={ classNames(
        `flex-shrink-0 group relative rounded-full inline-flex items-center justify-center h-5 w-10 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-${ config.primaryColor }-500 transition-all`,
        disabled ? "cursor-not-allowed opacity-80" : "cursor-pointer",
      ) }
    >
      <span aria-hidden="true" className="pointer-events-none absolute bg-white w-full h-full rounded-md" />
      <span
        aria-hidden="true"
        className={ classNames(
          enabled ? `bg-${ config.primaryColor }-600` : 'bg-gray-200',
          'pointer-events-none absolute h-4 w-9 mx-auto rounded-full transition-colors ease-in-out duration-200'
        ) }
      />
      <span
        aria-hidden="true"
        className={ classNames(
          enabled ? 'translate-x-5' : 'translate-x-0',
          'pointer-events-none absolute left-0 inline-block h-5 w-5 border border-gray-200 rounded-full bg-white shadow transform ring-0 transition-transform ease-in-out duration-200'
        ) }
      />
    </Switch>
  )
}
