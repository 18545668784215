import { Field } from "components/base/MgForm/Field";
import { MgTextarea } from "components/base/MgTextarea";

export const TextareaField = (props) => {
  return (
    <Field { ...props }>
      { (fieldProps) => {
        return (
          <MgTextarea
            className="w-full"
            variant={ !!props.error ? "danger" : "default" }
            { ...fieldProps }
          />
        )
      } }
    </Field>
  );
};
