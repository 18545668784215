import { createContext, useEffect, useRef, useState } from "react";
import { useApi, useLegacyList } from "hooks";
import {
  ReadNotification,
  MarkAllNotificationsAsRead,
  MarkAllNotificationsAsArchived,
  MerchantUserNotification
} from "MgTypes";

const NotificationsContext = createContext(null);
const fetchCooldownSeconds = 60; // 1 minute.

function NotificationsProvider({ children }) {

  const api = useApi();
  const [pushNotifications, setPushNotifications] = useState([]);
  const lastUpdate = useRef(null);

  const notificationList = useLegacyList(MerchantUserNotification, { isArchived: false }, {
    pageSize: 5,
    stack: true
  });


  useEffect(() => {
    window.addEventListener("mousemove", onMouseMove);
    return () => window.removeEventListener("mousemove", onMouseMove);
  }, []);

  const onMouseMove = () => {
    if (lastUpdate.current) {
      const secondsSinceLastUpdate = (new Date() - lastUpdate.current) / 1000;
      if (secondsSinceLastUpdate < fetchCooldownSeconds) {
        // console.log(`fetch notifications: too soon (cooldown ends in ${ parseInt(fetchCooldownSeconds - secondsSinceLastUpdate) }s)`);
        return;
      }
    }
    lastUpdate.current = new Date();
    // console.log("fetch notifications...");
    setTimeout(notificationList.reloadPage, 1000);
  }

  const readNotification = async (id) => {
    await api.$execute(ReadNotification, { id })
    await notificationList.reloadPage();
  }

  const markAllAsRead = async () => {
    await api.$execute(MarkAllNotificationsAsRead, {});
    await notificationList.reloadPage();
  }

  const markAllAsArchived = async () => {
    await api.$execute(MarkAllNotificationsAsArchived, {});
    await notificationList.reloadPage();
  }

  // calculate current unread notifications count
  const totalUnreadNotifications = (notificationList.items.filter((i) => !i.isRead) || []).length
  const totalUnread = totalUnreadNotifications + pushNotifications.length

  return (
    <NotificationsContext.Provider value={ {
      markAllAsRead,
      markAllAsArchived,
      pushNotifications,
      notificationList,
      totalUnread,
      readNotification,
    } }>
      { children }
    </NotificationsContext.Provider>
  )
}

export { NotificationsContext, NotificationsProvider };