import { Link, useLocation } from "react-router-dom";
import { ArrowSmLeftIcon } from "@heroicons/react/outline";
import { MgTooltip } from "components/base/MgTooltip";
import { ChevronDoubleLeftIcon, ChevronDoubleRightIcon } from "@heroicons/react/solid";

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

export function SideNav(
  {
    items = [], // {name, icon, path, isBack?, isSectionTitle?}
    subItems = [],
    subItemsTitle = "More",
    minimized = false,
    onMinimizeClick = () => {},
    onMaximizeClick = () => {},
    className,
  }
) {
  const location = useLocation();
  return (
    <nav aria-label="Sidebar" className={ className }>
      <div className="space-y-1">
        { items.map((item) => {

          if (item.isBack) {
            return (
              <MgTooltip tooltip={ `Back to ${ item.name }` } place="right" disableTooltip={ !minimized } key={ item.name }>
                <Link
                  to={ item.path }
                  className="group flex items-center px-3 py-2 text-sm font-medium rounded-md"
                >
                  <ArrowSmLeftIcon
                    className="flex-shrink-0 -ml-1 mr-3 h-6 w-6 text-gray-400 group-hover:text-gray-500" />
                  { !minimized && <span className="truncate text-gray-500">{ item.name }</span> }
                </Link>
              </MgTooltip>
            )
          }

          if (item.isSectionTitle) {
            if (!!minimized) {
              return null;
            }
            return (
              <div className="text-gray-400 text-sm pb-1 pt-4 px-3 font-medium" key={ item.name }>{ item.name }</div>
            )
          }

          const active = location.pathname.startsWith(item.path);
          return (
            <MgTooltip tooltip={ item.name } place="right" disableTooltip={ !minimized } key={ item.name }>
              <Link
                to={ item.path }
                className={ classNames(
                  active ? 'bg-gray-200 text-gray-900' : 'text-gray-600 hover:text-gray-900',
                  'group flex items-center px-3 py-2 text-sm font-medium rounded-md'
                ) }
                aria-current={ active ? 'page' : undefined }
              >
                <item.icon
                  className={ classNames(
                    active ? 'text-indigo-500' : 'text-gray-400 group-hover:text-gray-500',
                    'flex-shrink-0 -ml-1 mr-3 h-6 w-6'
                  ) }
                  aria-hidden="true"
                />
                { !minimized && <span className="truncate">{ item.name }</span> }
              </Link>
            </MgTooltip>
          )
        }) }
      </div>
      { !!(subItems && subItems.length) && <div className="mt-8">
        <h3 className="px-3 text-xs font-semibold text-gray-500 uppercase tracking-wider" id="projects-headline">
          { subItemsTitle }
        </h3>
        <div className="mt-1 space-y-1" aria-labelledby="projects-headline">
          { subItems.map((item, itemIdx) => {
            const active = location.pathname.startsWith(item.path);
            return (
              <Link
                key={ itemIdx }
                to={ item.path }
                className={ classNames(
                  "group flex items-center px-3 py-2 text-sm font-medium rounded-md",
                  active ? 'bg-gray-200 text-gray-900' : 'text-gray-600 hover:text-gray-900',
                ) }
              >
                <span className="truncate">{ item.name }</span>
              </Link>
            )
          }) }
        </div>
      </div> }
      <div className="mt-2">
        <div
          className="group flex items-center px-3 py-2 text-sm font-medium rounded-md justify-center w-[40px]"
        >
          { !!minimized ?
            <ChevronDoubleRightIcon
              onClick={ onMaximizeClick }
              className="flex-shrink-0 -ml-1 h-4 w-4 text-indigo-400 group-hover:text-indigo-500 cursor-pointer"
            /> :
            <ChevronDoubleLeftIcon
              onClick={ onMinimizeClick }
              className="flex-shrink-0 -ml-1 h-4 w-4 text-indigo-400 group-hover:text-indigo-500 cursor-pointer"
            /> }
        </div>
      </div>
    </nav>
  )
}