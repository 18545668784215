import { createContext, useState } from "react";
import { MgGreetBox } from "components/base/MgGreetBox";
import { MgErrorBox } from "components/base/MgErrorBox";
import { useToastContext } from "hooks";


const AlertsContext = createContext(null);

const AlertsProvider = ({ children }) => {

  const [alerts, setAlerts] = useState([]);
  const { addToast } = useToastContext();

  const addAlert = ({ type, clearOthers = false, timeout = 10, ...props }) => {
    if (["info", "success", "error"].indexOf(type) === -1) {
      // invalid type.
      return;
    }

    if (type === "success") {
      addToast(props.message);
      return;
    }

    const alert = { type, ...props };
    if (clearOthers) {
      setAlerts([alert]);
    } else {
      setAlerts(items => [...items, alert]);
    }
    /*if (timeout > 0) {
      setTimeout(() => setAlerts(items => items.filter(x => x !== alert)), timeout * 1000);
    }*/
    // Todo: disabled as it causes re-renders when timeout reaches.
  }

  const removeAlert = (alert) => {
    setAlerts(items => items.filter(item => item !== alert));
  }

  const clearAlerts = (t = "") => {
    if (t) {
      setAlerts(items => items.filter(item => item.type !== t));
    } else {
      setAlerts([]);
    }
  }

  return (
    <AlertsContext.Provider value={ { addAlert, removeAlert, clearAlerts } }>
      { !!(alerts && alerts.length) && alerts.map((alert, alertIdx) =>
        <Alert
          key={ alertIdx }
          onClose={ () => removeAlert(alert) }
          { ...alert }
        />
      ) }
      { children }
    </AlertsContext.Provider>
  )
}

const Alert = ({ type, ...props }) => {
  switch (type) {
    case "success":
      return <MgGreetBox { ...props } />;
    case "info":
      return <MgGreetBox { ...props } />;
    case "error":
      return <MgErrorBox { ...props } />;
    default:
      return null;
  }
}

export { AlertsContext, AlertsProvider };