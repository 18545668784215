import { MgTextbox } from "components/base/MgTextbox/MgTextbox";
import { Field } from "components/base/MgForm/Field";

export const TextField = (props) => {
  return (
    <Field { ...props }>
      { (fieldProps) => {
        return (
          <MgTextbox
            className="w-full"
            variant={ !!props.error ? "danger" : "default" }
            { ...fieldProps }
          />
        )
      } }
    </Field>
  );
};
