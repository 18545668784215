import { MgSelect } from "components/base/MgSelect";
import { useEffect, useState } from "react";
import { XIcon } from "@heroicons/react/solid";
import { MgTooltip } from "components/base/MgTooltip";
import { pluralize } from "util/textUtil";
import { MgConfirmModal } from "components/base/MgConfirmModal";

export function MgSelectBox(
  {
    items = [],
    value = [],
    modelName = "Item",
    disabled = false,
    onChange,
    className,
    template,
    showItemNum = false,
  }
) {

  const [selectedItems, setSelectedItems] = useState(value);
  useEffect(() => setSelectedItems(value), [value]);
  useEffect(() => typeof onChange === "function" && onChange(selectedItems), [selectedItems]);

  const selectItem = (val) => {
    setSelectedItems(sItems => [val, ...sItems]);
  }

  const removeItem = (item) => {
    setSelectedItems(sItems => sItems.filter(val => val !== item.value));
  }

  const itemTemplate = item => typeof template === "function" ?
    template({ item, modelName, removeItem }) :
    defaultTemplate({ item, modelName, removeItem });

  const leftItems = items.filter(item => selectedItems.indexOf(item.value) === -1);

  return (
    <div className="bg-white border border-gray-300 text-sm rounded-md">
      <ul className="divide-y divide-gray-300">
        <li className="px-4 py-3 bg-gray-50 rounded-t-md">
          <MgSelect
            small
            items={ leftItems }
            placeholder={
              !!(items && items.length) ?
                leftItems.length ?
                  `Add ${ modelName } ...` :
                  "No more items." :
                "No items." }
            disabled={ disabled || !items || !items.length || !leftItems.length }
            onChange={ selectItem }
          />
        </li>
        { selectedItems.map(itemVal => ({
          value: itemVal,
          label: items.find(x => x.value === itemVal)?.label,
          content: items.find(x => x.value === itemVal)?.content
        })).map((item, itemIdx) => (
          <li key={ itemIdx } className="px-4 py-2 flex items-center">
            { showItemNum && <div className="text-gray-400 mr-2">
              { itemIdx + 1 }.
            </div> }
            <div className="flex-grow">
              { itemTemplate(item) }
            </div>
          </li>
        )) }
        { (!selectedItems || !selectedItems.length) &&
        <li className="px-4 py-3 text-center text-gray-400 italic text-xs">
          No { pluralize(modelName).toLowerCase() } selected.
        </li> }
      </ul>
    </div>
  )

}

const defaultTemplate = ({ item, modelName, removeItem }) => (
  <div className="group flex items-center text-gray-600">
    { item.content || item.label }
    <div className="flex-grow" />
    <MgTooltip tooltip={ `Remove ${ modelName.toLowerCase() }` } place="left">
      <MgConfirmModal
        title={ `Remove ${ modelName } Item` }
        onConfirm={ () => removeItem(item) }
        buttonVariant="danger"
        activator={
          <XIcon className="h-4 w-4 mr-1 text-transparent group-hover:text-gray-400 transition-colors cursor-pointer" />
        }>
        Do you really want to remove "{ item.label }" from this list?
      </MgConfirmModal>
    </MgTooltip>
  </div>
)