import { urls, env } from "mg.env";

const config = {
  env,
  urls,
  primaryColor: "indigo",
  authStorageName: "mgauth",
};

export default config;
