import { MgForm } from "components/base/MgForm";
import { useEffect, useState } from "react";
import { MgErrorBox } from "components/base/MgErrorBox";

export function PasswordInput(
  {
    withConfirm = false,
    inputOneDescription,
    onChange = (val) => {},
    ...props
  }) {
  const [value, setValue] = useState('')
  const [oldPassword, setOldPassword] = useState('')
  const [error, setError] = useState(null);
  const regExpPassword = /^(?=.*[0-9])(?=.*[a-zA-Z])[a-zA-Z0-9!@#$%^&*()-_+=<>?]*$/;

  useEffect(() => {
    if (!withConfirm) {
      onChange(value)
      return
    }
    if ((oldPassword && !!oldPassword.length) && (value && !!value.length)) {
      if (value === oldPassword) {
        onChange(value)
        setError(null)
      } else {
        setError({ message: "passwords must be the same" })
        onChange("")
      }
      if (!regExpPassword.test(value.trim())) {
        setError({ message: "password must contain at least one letter and one number" })
        onChange("")
      }
      if (value.length < 7) {
        setError({ message: "password must be at least 7 characters" })
        onChange("")
      }
      if (value.length > 64) {
        setError({ message: "password must be less than 64 characters" })
        onChange("")

      }
    }
  }, [oldPassword, value])
  return (
    <div>
      <MgForm.TextField
        key="passwordNew"
        name="password"
        type="password"
        description={ ( !!inputOneDescription ? inputOneDescription : (!!props.description ? props.description : "Enter Password")) }
        className="mt-2"
        value={ value }
        onChange={ (e) => setValue(e.target.value) }
      />
      { withConfirm && <MgForm.TextField
        key="confirmPassword"
        type="password"
        name="confirmPassword"
        id="confirmPassword"
        description="Confirm Password"
        className="mt-2"
        value={ oldPassword }
        onChange={ (e) => setOldPassword(e.target.value) } />
      }
      <div className="mt-2">{ !!error && <MgErrorBox { ...error } /> }</div>
    </div>

  )
}