import { useContext, useEffect, useState } from "react";
import { useApi } from "hooks/useApi";
import { AlertsContext } from "contexts";
import { usePermissions } from "hooks/usePermissions";

export function useModel(type, params) {

  const api = useApi();
  const [instance, setInstance] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const alerts = useContext(AlertsContext);
  const paramsJson = JSON.stringify(params);
  const { canView } = usePermissions();
  const isPermitted = canView(type);

  const load = async (silent = false) => {
    if (!type || !isPermitted || !params || typeof params !== "object") {
      return;
    }

    let allEmpty = true;
    for (let k of Object.keys(params)) {
      if ([null, undefined].indexOf(params[k]) === -1) {
        allEmpty = false;
        break;
      }
    }
    if (allEmpty) {
      return;
    }

    console.log(`[useModel] loading ${ type.type }:${ paramsJson } ...`)

    if (!silent) {
      setLoading(true);
    }
    const res = await api.$get(type, params);
    if (res.ok) {
      const inst = res.object;
      setInstance(inst);
    } else {
      setError(res.error);
      if (alerts) {
        alerts.addAlert({ type: "error", ...res.error });
      }
    }
    if (!silent) {
      setLoading(false);
    }
  }

  useEffect(() => {
    if (loading) {
      return;
    }
    void load()
  }, [type, paramsJson]);

  return {
    isPermitted,
    instance,
    loading,
    error,
    load,
  };
}