import { ChevronRightIcon } from '@heroicons/react/solid'
import { Link } from "react-router-dom";
import { classNames } from "util/classUtil";

export function MgPageTitle(
  {
    title = "",
    subtitle = "",
    stats = [],
    breadcrumbs = [],
    actions,
    className,
  }
) {
  return (
    <div className={ classNames(
      "md:flex md:items-center md:justify-between relative z-10",
      className,
    ) }>
      <div className="flex-1 min-w-0">
        { !!(breadcrumbs && breadcrumbs.length) && <Breadcrumbs items={ breadcrumbs } className="mb-3 md:mb-5" /> }
        <div className="mt-2" />
        <div className="flex flex-row items-center">

          <div>
            { !!subtitle && <div className="text-gray-400 font-medium text-sm">{ subtitle }</div> }

            <h2 className="text-2xl font-bold leading-7 text-color-strong sm:text-3xl sm:truncate">
              { title }
            </h2>

            { !!(stats && stats.length) &&
            <div className="flex flex-row flex-wrap">
              <TitleStats items={ stats } />
            </div> }
          </div>

        </div>

      </div>

      { !!actions && <div className="flex pb-2 md:pb-0 md:ml-4 relative top-[20px]">
        { actions }
      </div> }

    </div>
  )
}

const TitleStats = ({ items = [] }) => { // {text, icon, path?}

  const content = (item) => <>
    { !!item?.icon && <item.icon className="flex-shrink-0 mr-1 md:mr-1.5 h-4 w-4 md:h-5 md:w-5 text-gray-400" aria-hidden="true" /> }
    { item.text }
  </>;

  return (
    <>
      { items.filter(item => !!!item.hide).map((item, itemIdx) => (
        <div key={ itemIdx }>
          { !!item.path && <Link
            to={ item.path }
            target="_blank"
            className="mt-2 mr-5 flex items-center text-xs md:text-sm text-gray-500 hover:text-primary-600 transition-colors">
            { content(item) }
          </Link> }
          { !item.path && <div className="mt-2 mr-2 md:mr-5 flex items-center text-xs md:text-sm text-gray-500">
            { content(item) }
          </div> }
        </div>
      )) }
    </>
  )
}

const Breadcrumbs = (
  { items = [], className }
) => { // {name, path}
  return (
    <nav aria-label="Breadcrumb" className={ className }>
      { !!(items && items.length) && <ol className="flex items-center flex-wrap">
        <li className="hidden md:inline-block pb-0.5 md:pb-0">
          <div className="flex items-center">
            <Link to="/" className="text-xs md:text-sm font-medium text-gray-500 hover:text-gray-700">
              Home
            </Link>
          </div>
        </li>
        { items.map((item, itemIdx) => (
          <li key={ itemIdx } className="pb-0.5 md:pb-0">
            <div className="flex items-center">
              <ChevronRightIcon className={ classNames(
                "mx-0.5 sm:mx-2 flex-shrink-0 h-5 w-5 text-gray-400",
                itemIdx === 0 && "hidden md:inline-block",
              ) } aria-hidden="true" />
              <Link to={ item.path } className={ classNames(
                "text-xs md:text-sm font-medium transition-colors",
                (itemIdx === items.length - 1) ? "text-indigo-600 hover:text-indigo-700" : "text-gray-500 hover:text-gray-700",
              ) }>
                { item.name }
              </Link>
            </div>
          </li>
        )) }
      </ol> }
    </nav>
  );
}
