import { Fragment, useEffect, useState } from 'react'
import { Dialog, Transition } from '@headlessui/react'

export function MgModal(
  {
    children,
    isOpen = false,
    activator = <></>,
    onChange = (isOpen) => {}, // on open/close
  }
) {

  const [open, setOpen] = useState(isOpen);
  useEffect(() => setOpen(isOpen), [isOpen]);
  useEffect(() => onChange(open), [open]);

  return (
    <>
      {
        typeof activator === "function" ?
          activator({ setOpen }) :
          <span onClick={ () => setOpen(true) }>{ activator }</span>
      }

      <Transition.Root show={ open } as={ Fragment }>
        <Dialog as="div" static className="fixed z-40 inset-0 overflow-y-auto" open={ open } onClose={ setOpen }>
          <div className="flex items-center justify-center min-h-screen text-center sm:block">
            <Transition.Child
              as={ Fragment }
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-50 transition-opacity" />
            </Transition.Child>

            <Transition.Child
              as={ Fragment }
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <div
                className="inline-block align-bottom bg-white rounded-lg text-left shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full md:max-w-xl lg:max-w-2xl">
                <div>
                  { typeof children === "function" ? children({ setOpen }) : children || <button /> }
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>
    </>
  )
}