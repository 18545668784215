import {PasswordInput} from "components/common/PasswordInput";
import {useEffect, useState} from "react";
import {MgForm} from "components/base/MgForm";
import {useHistory, useLocation} from "react-router-dom";
import {MgButton} from "components/base/MgButton";
import {$recoveryResetPassword, $validateRecoveryToken} from "api/recovery";
import {CheckCircleIcon} from "@heroicons/react/solid";
import {MgErrorBox} from "../../components/base/MgErrorBox";

const useQuery = () => {
  return new URLSearchParams(useLocation().search);
}

export function PasswordRecovery() {
  const [newPassword, setNewPassword] = useState("");
  const [isTokenExpired, setIsTokenExpired] = useState()
  const [isResetSuccessfully, setIsResetSuccessfully] = useState(false)
  // const history = useHistory(); // was not used
  let query = useQuery();
  const [error, setError] = useState(null);
  const samePasswordErrMsg = "new password must be different than old password";
  const passwordValidationMsg = "password validation error:"

  const token = query.get("t")

  const resetUserPassword = async () => {

    const res = await $recoveryResetPassword({token, newPassword});
    if (res.ok) {
      setIsResetSuccessfully(true)
    } else {
      // set error only if it is specifically same password related or password validation related
      if (!!res.error) {
        if (!!res.error.message.includes(samePasswordErrMsg) || !!res.error.message.includes(passwordValidationMsg)) {
          setError(res.error);
        }
      }
    }
  }
  const validateToken = async (token) => {
    const res = await $validateRecoveryToken({token});
    if (res.ok) {
      setIsTokenExpired(false)
    } else {
      setIsTokenExpired(true)
    }
  }

  useEffect(() => {
    if (token) {
      void validateToken(token)
    }
  }, [token])
  return (
    <div className="min-h-screen bg-gray-200 flex flex-col justify-center py-12 sm:px-6 lg:px-8">
      <div className="mt-6 sm:mx-auto sm:w-full sm:max-w-md">
        <div className="bg-white py-8 px-4 shadow rounded-lg sm:px-10 md:mx-10 mx-6">
          {isTokenExpired === true && <div className=" w-full">
            <h2 className="text-xl sm:text-xl font-medium text-color-strong">
              Invalid Token
            </h2>
            Sorry, the token has expired. You need to create a new recovery mail.
            <MgButton
              onClick={() => window.location.href = "/"}
              variant="primary"
              className="w-full mt-4"
            >
              <div className="py-2">
                Login page
              </div>
            </MgButton>
          </div>}

          {isTokenExpired === false && <>
            {isResetSuccessfully ? <div className="w-full text-center">

              <CheckCircleIcon className="h-16 w-16 text-green-400 my-4 inline-block" aria-hidden="true"/>

              <p>Your password has been reset successfully. You can now go back and log in</p>
              <MgButton
                onClick={() => window.location.href = "/"}
                variant="primary"
                className="w-full mt-4"
              >
                <div className="py-2">
                  Login page
                </div>
              </MgButton>
            </div> : <>
              <div className="flex items-center justify-between w-full">
                <h2 className="text-lg sm:text-xl font-medium text-color-strong">
                  Reset Password
                </h2>
                <img
                  className="h-8 w-auto rounded-md shadow"
                  src="https://mg-static-content.s3.eu-central-1.amazonaws.com/zotapay/zp-indigo.png"
                  alt="Zotapay"
                />

              </div>
              <div className="text-sm  text-gray-700 my-6">Please enter your new password</div>
              <div className="flex-none block w-full">
                <MgForm>
                  <PasswordInput withConfirm value={newPassword} onChange={(val) => setNewPassword(val)}/>
                  <div className="">{!!error && <MgErrorBox
                    closeable={true}
                    onClose={() => setError(null)}
                    {...error}
                  />}</div>
                  <MgButton
                    onClick={() => resetUserPassword()}
                    variant="primary"
                    className="w-full mt-4"
                  >
                    <div className="py-2">
                      Reset Password
                    </div>
                  </MgButton>
                </MgForm>
              </div>
            </>}
          </>}

        </div>
      </div>

    </div>
  )
}