import { useContext } from "react";
import { AuthContext } from "contexts";
import { camelize } from "util/textUtil";

export function usePermissions() {

  const { user, isPspUser, enableRegionScoping } = useContext(AuthContext);

  const hasRole = n => !!isPspUser || !!user.isRoot || user.groups.indexOf(n) !== -1;

  const canView = (model) => {
    if (!user) {
      return false;
    }

    if (!!user.isRoot || !!isPspUser) {
      return true;
    }

    if (!model || !model.type) {
      return false;
    }

    if (!model.requirePermission) {
      return true;
    }

    if (!model.permissionRole) {
      return true;
    }

    return hasRole(model.permissionRole);

    /*const n = camelize(model.type);
    if (!n) {
      return false;
    }

    let perms = [];
    if (isPspUser) {
      perms = ((permissions || {})["psp"] || []);
    } else if (!!userMid) {
      perms = ((permissions || {})[userMid] || []);
    } else {
      perms = defaultClientPermissions;
    }

    const s = `allow:${ n }.`;
    return !!perms.find(p => p.indexOf(s) !== -1);*/
  }

  const canExecute = (cmd) => {
    if (!user) {
      return false;
    }

    if (!!user.isRoot || !!isPspUser) {
      return true;
    }

    if (!cmd || !cmd.type || !cmd.model) {
      return false;
    }

    if (!cmd.requirePermission) {
      return true;
    }

    if (!cmd.permissionRole) {
      return true;
    }

    return hasRole(cmd.permissionRole);

    /*let perms = [];
    if (isPspUser) {
      perms = ((permissions || {})["psp"] || []);
    } else if (!!userMid) {
      perms = ((permissions || {})[userMid] || []);
    } else {
      perms = defaultClientPermissions;
    }

    const n = cmd.type;
    const rn = camelize(cmd.model);
    return perms.filter(p => [`allow:${ rn }.*`, `allow:${ rn }.${ n }`].indexOf(p) !== -1).length > 0;*/
  }

  const hasRoleReports = hasRole("m_rp");
  const hasRoleCustomerInfo = hasRole("m_custinfo");
  const hasRoleAccounting = hasRole("m_accterm") && !enableRegionScoping; // https://metagate.atlassian.net/browse/MG-4318
  const hasRolePayoutBatch = hasRole("m_bu");
  const hasRoleSinglePayouts = hasRole("m_sp");
  const hasRoleRefunds = hasRole("m_rv");
  const hasRoleServiceCases = hasRole("m_cases");
  const hasRolePayoutManualAuthorization = hasRole("m_pma");
  const hasRoleCryptoInfo = hasRole("m_crypto");

  return {
    canView,
    canExecute,
    hasRoleReports,
    hasRoleCustomerInfo,
    hasRoleAccounting,
    hasRolePayoutBatch,
    hasRoleSinglePayouts,
    hasRoleRefunds,
    hasRoleServiceCases,
    hasRolePayoutManualAuthorization,
    hasRoleCryptoInfo,
  }
}

const defaultClientPermissions = [
  "allow:merchant.read",
  "allow:order.read",
];