import { Field } from "components/base/MgForm/Field";
import { MgCheckbox } from "components/base/MgCheckbox";

export const CheckField = (props) => {
  return (
    <Field hideLabels={ true } { ...props }>
      { (fieldProps, helpers) => {
        return (
          <MgCheckbox
            value={ !!fieldProps.value }
            onChange={ (val) => helpers.setValue(!!val) }
            { ...props }
          />
        )
      } }
    </Field>
  );
};
