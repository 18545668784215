import { useLayoutEffect } from "react";


export const useClickOutside = (ref, callback) => {
  const handleClick = e => {
    if (ref.current && !ref.current.contains(e.target)) {
      callback();
    }
  };

  // here we are using useLayoutEffect instead of useEffect as we are directly manipulating the dom
  useLayoutEffect(() => {
    const mainWrapper = document.getElementById('root')
    mainWrapper.addEventListener('click', handleClick);
    return () => {
      mainWrapper.removeEventListener('click', handleClick);
    };
  });
};