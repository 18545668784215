import { ObjectsCache } from "MgTypes";
import { useContext, useState } from "react";
import { useApi } from "hooks/useApi";
import { CacheContext } from "contexts";

export function useCache() {

  const cache = useContext(CacheContext);
  const { dispatch } = cache;
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const api = useApi();

  const load = async () => {
    if (loading) {
      return;
    }
    setLoading(true);
    const res = await api.$loadView(ObjectsCache);
    if (res.ok) {
      dispatch({ type: "set", data: { ...res.object } });
    } else {
      setError(res.error);
    }
    setLoading(true);
  }

  const getObject = (collectionName = "", id = "") => {
    if (!cache.loaded) {
      return null;
    }

    const match = (cache[collectionName] || []).filter((obj) => obj.id.toString() === id.toString());
    if (match && match.length) {
      return match[0];
    }
    return null;
  }

  const isWarm = cache.loaded;

  return {
    isWarm,
    error,
    objects: cache,
    async warmup() {
      if (cache.loaded || loading) {
        return;
      }
      await load();
    },
    invalidate() {dispatch({ type: "clear" })},
    endpoint(id = "") {return getObject("endpoints", id)},
    region(id = "") {return getObject("regions", id)},
    paymentMethod(id = "") {return getObject("paymentMethods", id)},
  }
}