import { classNames } from "util/classUtil";
import config from "mg.config";
import React from "react";

export function NativeSelect(
  {
    selectRef,
    options = [],
    value,
    disabled = false,
    onChange = (val) => {},
    placeholder = "",
    small = false,
    xsmall = false,
    naked = false,
    className = "",
    style = {},
  }
) {
  return (
    <Select
      ref={ selectRef }
      className={ classNames(
        "pl-3 border-gray-300 focus:outline-none rounded-md transition-all",
        `focus:ring-${ config.primaryColor }-500 focus:border-${ config.primaryColor }-500`,
        xsmall ? "pl-1.5 pr-0 py-0.5 text-xs" : small ? "pl-2 pr-6 py-1.5 text-xs" : "pl-2 pr-6 py-1.5 text-xs sm:pl-3 sm:pr-6 sm:py-2 sm:text-sm",
        disabled ? "cursor-not-allowed" : "cursor-pointer",
        className ? className : "inline-block"
      ) }
      value={ value || "" }
      disabled={ disabled }
      onChange={ (e) => onChange(e.target.value) }
      placeholder={ placeholder }
      style={ style }
    >
      { !!placeholder && <option value="" disabled>{ placeholder }</option> }
      { options.map((opt, optIdx) =>
        <option key={ optIdx } value={ typeof opt === "object" ? opt.value : opt }>
          { typeof opt === "object" ? opt.label : opt }
        </option>)
      }
    </Select>
  )
}


const Select = React.forwardRef((props, ref) => (
  <select ref={ ref } { ...props } >
  </select>
));