const env = window.MG_ENV || "DEV";
const appUrls = {
  DEV: {
    officeApi: "http://dashboard.zota-dev.com:8050",
    merchantApi: "http://dashboard.zota-dev.com:1324",
    ssoApi: "http://dashboard.zota-dev.com:1323",
  },
  STAGE: {
    officeApi: "https://dashboard.zota-stage.com",
    merchantApi: "https://dashboard.zota-stage.com",
    ssoApi: "https://dashboard.zota-stage.com",
  },
  SANDBOX: {
    officeApi: "https://dashboard.zota-sandbox.com",
    merchantApi: "https://dashboard.zota-sandbox.com",
    ssoApi: "https://dashboard.zota-sandbox.com",
  },
  PROD: {
    officeApi: "https://dashboard.zota.com",
    merchantApi: "https://dashboard.zota.com",
    ssoApi: "https://dashboard.zota.com",
  },
};

const urls = appUrls[env];

export { env, urls };