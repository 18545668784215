import { MgModal } from "components/base/MgModal";
import { XIcon } from "@heroicons/react/solid";
import { MgButton } from "components/base/MgButton";
import { useState } from "react";
import { MgTextbox } from "components/base/MgTextbox/MgTextbox";

export function MgConfirmModal(
  {
    children,
    activator,
    isOpen = false,
    title = "",
    requireWord = "",
    onConfirm = () => {},
    onClose = (isConfirmed) => {},
    buttonVariant = "default",
  }
) {

  const [value, setValue] = useState("");
  const [isConfirmed, setIsConfirmed] = useState(false);
  const canSubmit = requireWord === value;

  const modalChanged = (isOpen) => {
    if (!isOpen) {
      setValue("");
      setIsConfirmed(false);
      onClose(isConfirmed);
    }
  }

  return (
    <MgModal isOpen={ isOpen } activator={ activator } onChange={ modalChanged }>
      { ({ setOpen }) => (

        <div className="w-full flex flex-col">
          <div className="flex px-5 py-4 text-lg text-color-strong items-center">
            { title }
            <div className="flex-grow" />
            <XIcon
              className="w-4 h-4 text-gray-400 hover:text-gray-600 cursor-pointer transition-colors"
              onClick={ () => setOpen(false) }
            />
          </div>
          <div className="bg-gray-50 border-b border-t border-gray-200 relative px-5 py-4 text-gray-600">

            { !!children && <div className="text-gray-600 text-base">{ children }</div> }

            { !!requireWord && <div className="text-gray-500 text-sm my-5">
              In order to proceed with this action,<br />
              Please type "<b><i>{ requireWord }</i></b>" below.
              <div className="mt-4">
                <MgTextbox
                  value={ value }
                  placeholder={ `Type "${ requireWord }" ...` }
                  onChange={ (e) => setValue(e.target.value) }
                  className="w-full"
                />
              </div>
            </div> }
          </div>
          <div className="px-5 py-5 flex flex-col">
            <div className="flex flex-row items-center justify-end">
              <MgButton
                variant={ canSubmit ? buttonVariant : "" }
                disabled={ !canSubmit }
                onClick={ () => {
                  setIsConfirmed(true);
                  setOpen(false);
                  onConfirm();
                } }
              >
                { title }
              </MgButton>
            </div>
          </div>
        </div>
      ) }
    </MgModal>
  )
}