import { useEffect, useState, useCallback, useRef } from 'react';

export function useTimeout(cb, displayTime = 3000) {
  const timeout = useRef();
  const callback = useRef(cb);
  const [isCleared, setIsCleared] = useState(false);


  const clearTimer = useCallback(() => {
    if (timeout.current) {
      clearTimeout(timeout.current);
      setIsCleared(true);
    }
  }, []);

  // saving the callback in a ref so the value is saved and not re-rendering
  useEffect(() => {
    if (typeof cb === 'function') {
      callback.current = cb;
    }
  }, [cb]);

  // using the displayTime in a dep's array so only when it changed - a new timer is set
  useEffect(() => {
    timeout.current = setTimeout(() => {
      callback.current();
    }, displayTime);
    return clearTimer;
  }, [displayTime]);

  // when component unmount clear the timeout
  useEffect(() => {
    return () => {
      clearTimer();
    }
  }, []);

  return [isCleared, clearTimer];
};