import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinnerThird } from "@fortawesome/pro-solid-svg-icons";
import { classNames } from "util/classUtil";

export function MgSpinner(
  {
    size = 18,
    block = false,
    className = "text-indigo-500",
  }
) {
  const Icon = () => (
    <FontAwesomeIcon
      icon={ faSpinnerThird }
      className={ classNames("animate-spin", className) }
      style={ { fontSize: size } }
    />
  )

  if (!!block) {
    return <div className="text-center py-5"><Icon /></div>
  }
  return <Icon />;
}