import { useState } from "react";
import { useApi } from "hooks/useApi";
import { ChartView } from "MgTypes";
import moment from "moment";

export function useCharts(
  {
    resourceType = null,
    resourceId = null,
    chartIds = [],
  }
) {

  const api = useApi();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [charts, setCharts] = useState({}); // { chartId: data, ... }

  const loadChart = async (chartId, fromDate, toDate, withCompare = false, fromDate2, toDate2, dateType, granularity) => {
    if (!chartId || !fromDate || !toDate) {
      return;
    }
    if (!!withCompare && (!fromDate2 || !toDate2)) {
      return;
    }
    if (!!!granularity) {
      granularity = null;
    }
    const params = {
      chartId,
      fromDate,
      toDate,
      withCompare,
      fromDate2,
      toDate2,
      dateType,
      resourceType,
      resourceId,
      granularity
    };
    const res = await api.$loadView(ChartView, params);
    setLoading(false);
    if (res.ok) {
      setCharts(ch => ({ ...ch, [chartId]: res.data }));
    } else {
      setError(res.error);
    }
  }

  const load = async (
    {
      fromDate,
      toDate,
      withCompare = false,
      fromDate2,
      toDate2,
      dateType,
      granularity,
      silent = false
    }
  ) => {
    if (loading) {
      return;
    }
    if (!Array.isArray(chartIds) || !!!chartIds.length) {
      return;
    }
    if (!!resourceType && !!!resourceId) {
      return;
    }
    if (!fromDate && !toDate) {
      const d = moment().utc();
      fromDate = d;
      toDate = d;
    }
    if (!silent) {
      setLoading(true);
    }
    setError(null);
    for (let chId of chartIds) {
      await loadChart(chId, fromDate, toDate, !!withCompare, fromDate2, toDate2, dateType, granularity);
    }
    if (!silent) {
      setLoading(false);
    }
  }

  const isLoaded = () => !loading && !!charts && !!Object.keys(charts).length;
  const getChart = chartId => !!(chartId in charts && !!charts[chartId]) ? charts[chartId] : null;

  return {
    loading,
    error,
    loaded: isLoaded(),
    load,
    getChart,
  }
}