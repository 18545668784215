export const mainMenuItems = (user, merchant, permissions, enableRegionScoping = false) => [
  {
    name: "Dashboard",
    path: "/home/",
    show: () => true,
  },
  {
    name: "Orders",
    path: "/orders/",
    show: () => true,
  },
  {
    name: "Account",
    path: `/account/`,
    show: () => true,
  },
  {
    name: "Balance",
    path: "/balance/",
    show: () => !!merchant?.hasBalance && !!permissions.hasRoleAccounting && !enableRegionScoping,
  },
  {
    name: "Payouts",
    path: "/payout-manager/",
    show: () => !!permissions.hasRolePayoutBatch || !!permissions.hasRoleSinglePayouts || !!permissions.hasRolePayoutManualAuthorization,
  },
  {
    name: "Refunds",
    path: "/refunds-manager/",
    show: () => !!permissions.hasRoleRefunds && !!permissions.hasRoleCustomerInfo
  },
  {
    name: "Reports",
    path: `/reports/`,
    show: () => !!permissions.hasRoleReports,
  },
  {
    name: "Support",
    path: `/support/cases/`,
    show: () => !!permissions.hasRoleServiceCases,
  },
  {
    name: "Administration",
    path: `/admin/`,
    show: () => !!user?.isRoot,
  },
];