import { DatePicker, ConfigProvider } from "antd";
import "styles/_antd.css"
import 'moment/locale/en-gb';
import locale from 'antd/es/locale/en_US';
import moment from "moment";

export function AntDatePicker(
  {
    value,                  // moment date object.
    onChange = (val) => {}, // moment date object.
    disabled = false,
    withTime = false,
    width = 200,
    style,
    ...props
  }
) {

  moment.locale('en-us', { week: { dow: 1 } });

  const combinedStyle = {
    width,
    borderRadius: 4,
    height: 35,
    fontWeight: "light",
    ...(style || {}),
  };

  const disabledDate = d => {
    return d.isAfter(moment().utc().endOf('day'));
  }

  return (
    <div className="flex items-center">
      <ConfigProvider locale={ locale }>
        <DatePicker
          value={ value }
          onChange={ onChange }
          style={ combinedStyle }
          showTime={ !!withTime }
          showNow={ !!withTime }
          disabled={ !!disabled }
          disabledDate={ disabledDate }
          { ...props }
        />
      </ConfigProvider>
    </div>

  )
}