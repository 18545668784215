import { createContext } from "react";
import { MerchantUser } from "MgTypes";
import { ModelViewProvider } from "contexts/index";
import { IdentificationIcon, MailIcon } from "@heroicons/react/solid";


const MyAccountContext = createContext(null);

const MyAccountProvider = ({ id, children }) => {
  return (
    <ModelViewProvider
      context={ MyAccountContext }
      type={ MerchantUser }
      typeDisplayName="User Account"
      params={ { userId: id } }
      navItems={ () => [] }
      title="My Account"
      titleStats={ titleStats }
      breadcrumbs={ (user) => [
        {
          name: `My Account`,
          path: `/my-account/`
        },
      ] }
    >
      { children }
    </ModelViewProvider>
  )
}

const titleStats = (inst) => [
  {
    text: `${ inst.firstName } ${ inst.lastName }`,
    icon: IdentificationIcon
  },
  {
    text: inst.email,
    icon: MailIcon
  },
];

export { MyAccountContext, MyAccountProvider };