import { useContext, useEffect, useState } from "react";
import { useApi } from "hooks/useApi";
import { AlertsContext } from "contexts";

export function useView(type, params, autoLoad = true) {

  const api = useApi();
  const [viewData, setViewData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const alerts = useContext(AlertsContext);
  const paramsJson = JSON.stringify(params);

  const load = async (silent = false) => {
    if (!type) {
      return;
    }
    if (!params) {
      params = {};
    }

    console.log(`[useView] loading ${ type.type }:${ paramsJson } ...`)

    if (!silent) {
      setLoading(true);
    }
    const res = await api.$loadView(type, params);
    if (res.ok) {
      setViewData(res.data);
    } else {
      setError(res.error);
      if (alerts) {
        alerts.addAlert({ type: "error", ...res.error });
      }
    }
    if (!silent) {
      setLoading(false);
    }
  }

  useEffect(() => {!!autoLoad && void load()}, [type, paramsJson]);

  return {
    viewData,
    loading,
    error,
    load,
  };
}