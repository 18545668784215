import { useContext, useEffect, useState } from "react";
import { PageContext } from "contexts/PageContext";
import { useGlobalReloader, useModel } from "hooks";
import { useLocation } from "react-router-dom";
import { CubeIcon } from "@heroicons/react/solid";
import { toSentence } from "util/textUtil";
import { AlertsContext } from "contexts/AlertsContext";
import { MgSpinner } from "components/base/MgSpinner";
import { Mg401 } from "components/base/Mg401";

const ModelViewProvider = (
  {
    context,
    type,
    params,
    children,
    title = "",
    navItems = (inst) => [],
    breadcrumbs = (inst) => [],
    titleStats = (inst) => [],
    titleActions = (inst, model) => "",
    typeDisplayName,
    hideNavigation = false,
  }
) => {

  const model = useModel(type, params);
  const location = useLocation();
  const { clearAlerts } = useContext(AlertsContext);
  const [isSubContext, setIsSubContext] = useState(false);
  const modelLess = !!!type;
  const {
    setBreadcrumbs,
    setPageTitle,
    setPageSubTitle,
    clearPageData,
    setPageTitleStats,
    setPageTitleActions,
    setNavItems,
  } = useContext(PageContext);

  useEffect(() => {
    if (modelLess) {
      setPageData();
      return;
    }

    if (model?.loading || !model?.instance) {
      return;
    }
    setPageData(model.instance);
  }, [model?.loading, model?.instance]);

  useGlobalReloader(() => {
    if (modelLess || !!!model) {
      return;
    }
    void model.load();
  });

  const getTitleStats = (inst) => {
    const stats = titleStats(inst) || [];
    if (modelLess) {
      return stats;
    }
    const typeStat = { text: toSentence(typeDisplayName || type.type), icon: CubeIcon };
    return [typeStat, ...stats];
  }

  const setPageData = (inst) => {

    if (!modelLess && !model?.isPermitted) {
      clearPageData();
      return;
    }

    let actions = !modelLess && typeof titleActions === "function" ? titleActions(inst, model) || "" : "",
      subtitle = "",
      isSubContext = false,
      hideNav = !!hideNavigation,
      found = false;

    const nav = navItems(inst).filter(x => !x.hide);
    let bc = breadcrumbs(inst) || [];
    for (let item of nav) {
      if (location.pathname === item.path && !!item.hideNav) {
        hideNav = true;
      }
      if (item.isBack || item.isSectionTitle) {
        continue;
      }
      if (location.pathname.startsWith(item.path)) {
        bc.push({ name: item.name, path: item.path });
        actions = item.actions || "";
        subtitle = item.name;
        isSubContext = !!item.hasContext && location.pathname !== item.path;
        found = true;
        break;
      }
    }

    setIsSubContext(isSubContext);

    if (hideNav) {
      setNavItems([]);
    }

    if (!isSubContext) {
      setPageTitle(modelLess ? title : title || inst.name);
      setPageSubTitle(found ? subtitle : "")
      setBreadcrumbs(bc);
      setPageTitleActions(actions);
      setPageTitleStats(getTitleStats(inst));
      if (!hideNav) {
        setNavItems(nav);
      }
    }
  }

  useEffect(() => {
    clearAlerts("error");
    if (modelLess) {
      setPageData();
    } else if (!!model?.instance) {
      setPageData(model.instance);
    }
    return () => {
      if (!isSubContext) {
        clearPageData();
      }
    };
  }, [location?.pathname]);

  if (!modelLess) {
    if (model?.loading || !model?.instance) {
      return <div className="text-center px-5"><MgSpinner /></div>;
    }

    if (!model.isPermitted) {
      return <Mg401 />;
    }
  }

  return (
    <context.Provider value={ { instance: model?.instance, load: model?.load } }>
      <div>
        { children }
      </div>
    </context.Provider>
  )
}

export { ModelViewProvider };