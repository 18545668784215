import { XCircleIcon, XIcon } from "@heroicons/react/solid";

export function MgErrorBox(
  {
    code = "error",
    name = "Error",
    message = "We had a problem processing your request.",
    trace = "",
    errors = [],
    showFieldErrors = true,
    closeable = true,
    onClose = () => {},
  }
) {
  return (
    <div className="rounded-md bg-red-50 p-4 mb-4 border border-red-200">
      <div className="flex">
        <div className="flex-shrink-0">
          <XCircleIcon className="h-5 w-5 text-red-400" aria-hidden="true" />
        </div>
        <div className="ml-3 text-red-800">

          <h3 className="text-sm font-medium flex">
            { name }
          </h3>

          { !!message && <div className="text-sm font-medium my-1">{ message }</div> }

          { !!trace && <div className="text-sm font-medium my-1">(TraceID: { trace })</div> }

          { !!(errors && errors.length && showFieldErrors) && <div className="mt-2 text-sm text-red-700">
            <ul className="list-disc pl-5 space-y-1">
              { errors.map((err, errIdx) => (
                <li key={ errIdx }>{ err.field }: { err.message }</li>
              )) }
            </ul>
          </div> }

        </div>
        <div className="ml-auto pl-3">
          { !!closeable && <div className="-mx-1.5 -my-1.5">
            <button
              type="button"
              className="inline-flex bg-red-50 rounded-md p-1.5 text-red-500 hover:bg-red-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-red-50 focus:ring-red-600"
              onClick={ onClose }
            >
              <span className="sr-only">Dismiss</span>
              <XIcon className="h-5 w-5" aria-hidden="true" />
            </button>
          </div> }
        </div>
      </div>
    </div>
  )
}
