import ReactTooltip from 'react-tooltip';
import { randStr } from "util/randUtil";

export function MgTooltip(
  {
    children,
    tooltip,
    delayShow = 25,
    disableTooltip=false,
    ...restProps
  }
) {

  if (!!disableTooltip) {
    return children;
  }

  const id = randStr();
  return (
    <>
      <span data-tip data-for={ id }>
        { children }
      </span>
      <ReactTooltip
        id={ id }
        delayShow={ delayShow }
        // backgroundColor="rgba(0,0,0,0.9)"
        effect="solid"
        border
        getContent={ () => (
          <div className="-m-2 -mx-5 p-1 text-xs text-center whitespace-pre-wrap z-10">
            { tooltip }
          </div>
        ) }
        { ...restProps }
      />
    </>
  )
}