import { createContext, useState, Fragment } from "react";
import { AlertsProvider } from "contexts";
import { classNames } from "util/classUtil";
import { Link, useLocation } from "react-router-dom";
import { MgSpinner } from "components/base/MgSpinner";
import { MgPageTitle } from "components/base/MgPageTitle";
import { SideNav } from "components/base/MgNavigationPage/SideNav";
import { Popover, Transition } from "@headlessui/react";
import { MenuAlt3Icon, XIcon } from "@heroicons/react/outline";

const PageContext = createContext({});

const PageProvider = (
  {
    addGlobalReloadHandler = cb => {},
    removeGlobalReloadHandler = cb => {},
    children
  }
) => {

  const [pageLoading, setPageLoading] = useState(false);
  const [pageTitle, setPageTitle] = useState("");
  const [pageSubTitle, setPageSubTitle] = useState("");
  const [pageTitleStats, setPageTitleStats] = useState([]); // {text, icon}
  const [pageTitleActions, setPageTitleActions] = useState(""); // jsx
  const [navItems, setNavItems] = useState([]); // {name, icon, path}
  const [breadcrumbs, setBreadcrumbs] = useState([]); // {name, path}
  const [miniSideNav, setMiniSideNav] = useState(false);

  if (pageLoading) {
    return <div className="text-center p-5"><MgSpinner /></div>;
  }

  const clearPageData = () => {
    setPageTitle("");
    setPageSubTitle("");
    setPageTitleStats([]);
    setPageTitleActions("");
    setBreadcrumbs([]);
    setNavItems([]);
  }

  const withNav = !!(navItems && navItems.length);

  return (
    <PageContext.Provider value={ {
      setPageLoading,
      setBreadcrumbs,
      setPageTitle,
      setPageSubTitle,
      setPageTitleStats,
      setPageTitleActions,
      setNavItems,
      clearPageData,
      addGlobalReloadHandler,
      removeGlobalReloadHandler,
    } }>
      <AlertsProvider>
        <div className={ classNames(
          withNav ? miniSideNav ? "lg:grid lg:grid-cols-12" : "lg:grid lg:grid-cols-12 lg:gap-x-5" : "",
        ) }>
          { withNav &&
          <>
            <aside className={ classNames(
              "hidden lg:block lg:px-0 z-20",
              withNav ? miniSideNav ? "lg:col-span-1 w-[40px]" : "lg:col-span-3 xl:col-span-2" : "",
            ) }>
              <SideNav
                items={ navItems }
                className="lg:relative lg:sticky lg:top-32"
                minimized={ !!miniSideNav }
                onMinimizeClick={ () => setMiniSideNav(true) }
                onMaximizeClick={ () => setMiniSideNav(false) }
              />
            </aside>
            <div className="lg:hidden mb-0">
              <MobileNav
                navItems={ navItems.filter(item => !item.hide && !item.isBack) }
                pageTitle={ pageTitle }
                pageSubTitle={ pageSubTitle }
              />
            </div>
          </> }
          <div className={ classNames(
            "space-y-5 sm:px-6 lg:px-0 lg:mt-2",
            // withNav && "lg:col-span-9 xl:pl-2",
            withNav ? miniSideNav ? "lg:col-span-11" : "lg:col-span-9 xl:col-span-10 xl:pl-2" : "",
          ) }>

            { !!pageTitle && <MgPageTitle
              title={ pageTitle }
              subtitle={ pageSubTitle }
              stats={ pageTitleStats }
              actions={ pageTitleActions }
              breadcrumbs={ breadcrumbs }
            /> }
            <div className="relative">
              { children }
            </div>
          </div>
        </div>
      </AlertsProvider>
    </PageContext.Provider>
  )
}

const MobileNav = ({ navItems = [], pageTitle, pageSubTitle }) => {
  const location = useLocation();
  const currentNavItem = navItems.find(item => location.pathname.startsWith(item.path));
  return (
    <Popover as="div" className="relative z-20">
      { ({ open }) => (
        <>
          <div className="flex items-center">
            <div className="flex-grow" />
            <div>
              <Popover.Button
                className="md:hidden rounded-md px-1.5 py-1.5 inline-flex items-center justify-center bg-gray-50 border text-indigo-500 hover:bg-gray-100">
                <span className="sr-only">Open menu</span>
                <MenuAlt3Icon className={
                  classNames(
                    "block h-5 w-5",
                    open && "text-indigo-500"
                  ) } aria-hidden="true" />
              </Popover.Button>
            </div>
          </div>
          <Transition.Root show={ open } as={ Fragment }>
            <div className="lg:hidden">
              <Transition.Child
                as={ Fragment }
                enter="duration-150 ease-out"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="duration-150 ease-in"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <Popover.Overlay static className="z-20 fixed inset-0 bg-black bg-opacity-25" />
              </Transition.Child>

              <Transition.Child
                as={ Fragment }
                enter="duration-150 ease-out"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="duration-150 ease-in"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Popover.Panel
                  focus
                  static
                  className="z-30 absolute top-0 inset-x-0 max-w-3xl mx-auto w-full p-2 transition transform origin-top"
                >
                  <div
                    className="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 bg-white divide-y divide-gray-200 overflow-hidden">
                    <div className="pt-2 bg-gray-50">
                      <div className="flex items-center justify-between px-4 pb-2.5 bg-gray-50 border-b">
                        <div>
                          <div className="font-medium text-colorStrong-700">{ pageTitle }</div>
                          { !!pageSubTitle && <div className="text-gray-400 text-xs">{ pageSubTitle }</div> }
                        </div>
                        <div className="-mr-2">
                          <Popover.Button
                            className="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500">
                            <span className="sr-only">Close menu</span>
                            <XIcon className="h-4 w-4" aria-hidden="true" />
                          </Popover.Button>
                        </div>
                      </div>
                      <div className="my-3 px-2">
                        { navItems.map((item) => {

                          if (item.isSectionTitle) {
                            return (
                              <div key={ item.name }
                                   className="text-gray-400 text-xs pb-1 pt-3 px-3 font-medium">{ item.name }</div>
                            )
                          }

                          return (
                            <Popover.Button as="div" key={ item.name }>
                              <Link
                                to={ item.path }
                                className={ classNames(
                                  "block rounded-md px-3 py-2 text-sm font-medium",
                                  item === currentNavItem ? "text-indigo-600" : "text-gray-900 hover:bg-gray-100 hover:text-gray-800",
                                ) }
                              >
                                { item.name }
                              </Link>
                            </Popover.Button>
                          )
                        }) }
                      </div>
                    </div>
                  </div>
                </Popover.Panel>
              </Transition.Child>
            </div>
          </Transition.Root>
        </>
      ) }
    </Popover>
  )
}


export { PageContext, PageProvider };