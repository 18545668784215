import React from 'react';
import { classNames } from "util/classUtil";
const SIZE_MAPS = {
  SMALL: 'px-1.5 text-xs',
  LARGE: 'px-3 text-sm',
};
const VARIANT_MAPS = {
  RED: 'bg-red-100 text-red-800',
  YELLOW: 'bg-yellow-100 text-yellow-800',
  GREEN: 'bg-green-100 text-green-800',
  BLUE: 'bg-blue-100 text-blue-800',
};
export function Badge( { children, variant, size }) {
  return (
    <span
      className={classNames(
        'relative py-0.5 rounded-full font-bold leading-4 whitespace-no-wrap pointer-events-none bg-opacity-90 shadow-sm',
        variant,
        size,
      )}
    >
            {children}
        </span>
  );
}
Badge.variant = VARIANT_MAPS;
Badge.size = SIZE_MAPS;