import { useCache } from "hooks/useCache";

export function useExchange() {

  const cache = useCache();

  const getMarketRate = (fromCurrency, toCurrency) => {
    const plan = cache.objects.marketRates;
    if (fromCurrency === toCurrency) {
      return 1;
    }
    if (!plan || !plan[fromCurrency] || !plan[fromCurrency][toCurrency]) {
      return null;
    }
    const r = parseFloat(plan[fromCurrency][toCurrency]);
    // reduce 7%.
    return r * .93;
  }

  const marketConvert = (fromCurrency, toCurrency, fromAmount) => {
    const rate = getMarketRate(fromCurrency, toCurrency);
    if (rate === null) {
      return null;
    }
    return parseFloat(fromAmount) * rate;
  }

  return {
    getMarketRate,
    marketConvert,
  }
}
