import { useEffect, useState } from "react";
import { randStr } from "util/randUtil";
import { classNames } from "util/classUtil";
import config from "mg.config";

export function MgCheckbox(
  {
    value = false,
    onChange = (val) => {},
    disabled = false,
    label = "",
    description = "",
    className,
    labelClassName = "",
  }
) {
  const [checked, setChecked] = useState(false);
  useEffect(() => setChecked(value), [value]);

  const onInputChange = (e) => {
    const val = !!e.target.checked;
    setChecked(val)
    onChange(val);
  }

  const id = randStr();
  return (
    <div className={ classNames("relative flex items-start", className) }>
      <div className="flex items-center h-5">
        <input
          id={ id }
          name={ id }
          checked={ checked }
          onChange={ onInputChange }
          disabled={ disabled }
          type="checkbox"
          className={ classNames(
            `focus:ring-${ config.primaryColor }-500 h-4 w-4 border-gray-300 rounded transition-colors`,
            disabled ? "opacity-75 text-gray-500" : `cursor-pointer text-${ config.primaryColor }-500`,
          ) }
        />
      </div>
      <div className="ml-2 text-sm">
        { !!label && <label htmlFor={ id } className={ classNames(
          labelClassName || "font-medium text-gray-700",
          disabled ? "opacity-75" : "cursor-pointer",
        ) }>{ label }</label> }
        { !!description && <p className="text-gray-500">{ description }</p> }
      </div>
    </div>
  )
}