import { useContext, useEffect, useState } from "react";
import { usePermissions } from "hooks/usePermissions";
import { useApi } from "hooks/useApi";
import { AlertsContext } from "contexts";
import moment from "moment";

export function useLegacyList(type, filters, options) {

  // ensure type.
  if (!type) {
    throw new Error(`useList received an empty type`);
  }

  // validate and parse inputs.
  filters = parseFilters(filters);
  options = parseOptions(options);

  if (["model", "view"].indexOf(options.resourceType) === -1) {
    throw new Error(`useList received an invalid resourceType, "${ options.resourceType }"`);
  }

  // setup resources.
  const { $filter } = useApi();
  const { canView } = usePermissions();
  const pageCtx = useContext(AlertsContext);
  const { resourceType } = options;
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [items, setItems] = useState([]);
  const [pageItems, setPageItems] = useState([]);
  const [currentPage, setCurrentPage] = useState(-1);
  const [totalItems, setTotalItems] = useState(0);
  const [extraFilters, setExtraFilters] = useState(options.extraFilters);
  const [query, setQuery] = useState(options.searchQuery);
  const [sortBy, setSortBy] = useState(null);
  const [sortDirection, setSortDirection] = useState(null);
  const [filtersChanged, setFiltersChanged] = useState(0);
  const [sortChanged, setSortChanged] = useState(0);

  const [pageSize, setPageSize] = useState(options.pageSize);
  const isPermitted = canView(type);
  const { searchQuery } = options;
  const totalPages = Math.ceil(totalItems / pageSize);
  const hasPrev = currentPage > 1;
  const hasNext = currentPage < totalPages || totalItems === -1;
  const pageFromItem = (pageSize * (currentPage - 1)) + 1;
  const pageToItem = Math.min(pageFromItem + pageSize - 1, totalItems);

  const loadPage = async (pageNum, reload = false) => {
    if (!isPermitted || loading) {
      return;
    }
    if (pageCtx) {
      pageCtx.clearAlerts("error");
    }
    pageNum = Math.max(1, pageNum);

    // if a stacked list tries to load 1st page, not reloading, with items already loaded - ignore the request
    if ((!reload && options.stack && pageNum === 1 && items.length > 2)) {
      return
    }

    const isStackReload = (reload && options.stack)
    const params = buildRequestParams(pageNum, pageSize, filters, extraFilters, sortBy, sortDirection, query, isStackReload);
    setLoading(true);
    setError(null);
    const res = await $filter(type, params, resourceType);
    if (res.ok) {
      const resItems = res.objects || [];
      setPageItems(resItems);
      setItems(options.stack ? (!reload ? [...items, ...resItems] : resItems) : resItems);
      setCurrentPage(pageNum);
      setTotalItems(res.pagination?.totalItems || 0);
      setLoading(false);
      return { items: resItems, totalItems: res.pagination?.totalItems || 0 };
    } else {
      setCurrentPage(-2);
      setError(res.error);
      if (pageCtx) {
        pageCtx.addAlert({ type: "error", ...res.error });
      }
    }
    setLoading(false);
    return {};
  }

  const loadFirstPage = async () => await loadPage(1);
  const loadNextPage = async () => await loadPage(currentPage + 1);
  const loadPrevPage = async () => await loadPage(currentPage - 1);
  const loadLastPage = async () => await loadPage(totalPages);
  const reloadPage = async () => await loadPage(currentPage, true);

  const applyFilters = async (extraFilters = []) => {
    extraFilters = parseFilters(extraFilters);
    setExtraFilters(extraFilters);
    setFiltersChanged(x => x + 1);
  }

  const applySearchQuery = async (q = "") => {
    setQuery(q);
  }

  const applySort = async (colId = "", dir = "") => {
    if (!colId) {
      setSortBy(null);
      setSortDirection("asc");
      setSortChanged(x => x + 1);
      return;
    }
    setSortBy(colId);
    setSortDirection(dir || "asc");
    setSortChanged(x => x + 1);
  }

  const selectPageSize = async (pageSize) => {
    if (isNaN(pageSize) || pageSize < 1 || pageSize > 100) {
      pageSize = 10;
    }
    setPageSize(parseInt(pageSize));
  }

  useEffect(() => {
    if (loading || currentPage < 1) {
      return;
    }
    void loadFirstPage()
  }, [type, query, pageSize, filtersChanged, sortChanged]); // JSON.stringify(filters),

  const ok = error === null;

  return {
    ok,
    loading,
    error,
    type,
    isPermitted,
    items,
    pageItems,
    currentPage,
    totalItems,
    totalPages,
    pageSize,
    filters,
    extraFilters,
    searchQuery,
    options,
    hasPrev,
    hasNext,
    pageFromItem,
    pageToItem,
    loadPage,
    loadFirstPage,
    loadNextPage,
    loadPrevPage,
    loadLastPage,
    reloadPage,
    applyFilters,
    applySearchQuery,
    applySort,
    selectPageSize,
  };
}

const defaultOptions = {
  pageSize: 10,
  resourceType: "model", // either `model` or `view`.
  stack: false,
  extraFilters: [],
  searchQuery: null,
};

/**************************/

const buildRequestParams = (pageNum, pageSize, filters, extraFilters, sortBy, sortDirection, query, isStackReload = false) => {
  let ret = {};

  // apply pagination.
  if (pageNum > 0 && pageSize > 0) {
    const page = {
      // handle reload current list items
      pageSize: isStackReload ? pageNum * pageSize : pageSize,
      pageNum: isStackReload ? 1 : pageNum,
    };
    ret = { ...ret, page };
  }

  // apply filters.
  if (extraFilters.length > 0) {
    filters = [...filters, ...extraFilters];
  }
  if (filters.length > 0) {
    ret = { ...ret, filters };
  }

  // apply sort.
  if (!!sortBy) {
    sortDirection = sortDirection || "asc";
    const sort = { sortBy, sortDirection };
    ret = { ...ret, sort };
  }

  // apply search query.
  if (query) {
    ret = { ...ret, query };
  }

  return ret;
}

const parseOptions = (options = {}) => {
  if (!options || typeof options !== "object") {
    options = {};
  }
  return { ...defaultOptions, ...options };
}

const parseFilters = (filters = []) => {
  let ret = [];
  if (Array.isArray(filters)) {
    ret = filters;
  } else if (typeof filters === "object" && Object.keys(filters || {}).length > 0) {
    ret = convertToFilters(filters || {});
  } else if (!filters || !Array.isArray(filters)) {
    ret = [];
  }
  for (let f of ret) {
    if (!isFilterValid(f)) {
      throw new Error(`invalid list filter, ${ JSON.stringify(f) }`)
    }
  }

  const isDateVal = val => typeof val?.getMonth === 'function';
  ret = ret.map(f => !!isDateVal(f.value) ? { ...f, type: "date", value: moment(f.value).format("YYYY-MM-DD") } : f);

  return ret;
}

const convertToFilters = (args = {}) => Object.keys(args).map(arg => ({
  property: arg,
  condition: "equals",
  value: args[arg]
}));

const isFilterValid = (f = {}) => !!(typeof f === "object" && !!f.property && !!f.condition && "value" in f);