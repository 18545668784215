import { useContext, lazy, Suspense, useEffect } from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import { AuthContext, BalanceProvider, CacheProvider } from "contexts";
import { MgSpinner } from "components/base/MgSpinner";
import { PasswordRecovery } from "pages/auth/PasswordRecovery";
import { AppLayout } from "components/layout/AppLayout";
import { getQueryParam } from "util/pathUtil";
import { useCache } from "hooks";

const MainRouter = lazy(() => import("routers/MainRouter"));
const Login = lazy(() => import("pages/auth/Login"));
const EmailVerification = lazy(() => import("pages/auth/EmailVerification"));

export function App() {

  const { user, logout, isAuthenticated, merchant } = useContext(AuthContext);

  useEffect(() => {
    let mid = getQueryParam("asmid");
    if (!!mid && typeof mid === "string" && mid.length >= 3) {
      logout();
      window.location.href = `${ window.location.pathname }?mid=${ mid }`;
    }
  }, []);

  if (!isAuthenticated) {
    return (
      <BrowserRouter>
        <Switch>
          <Route exact path="/recovery"><PasswordRecovery /> </Route>
          <Route path="/*"><Suspense fallback={ <MgSpinner block /> }> <Login /> </Suspense></Route>
        </Switch>
      </BrowserRouter>
    )
  }

  if (!user?.isEmailVerified) {
    return (
      <BrowserRouter>
        <Switch>
          <Route exact path="/*">
            <Suspense fallback={ <MgSpinner block /> }> <EmailVerification /> </Suspense>
          </Route>
        </Switch>
      </BrowserRouter>
    )
  }

  if (!merchant?.id) {
    return <MgSpinner block />;
  }

  return <Suspense fallback={ <MgSpinner block /> }><ClientApp /></Suspense>
}


const ClientApp = () => {
  return (
    <CacheProvider>
      <BalanceProvider>
        <InnerApp />
      </BalanceProvider>
    </CacheProvider>
  );
}

const InnerApp = () => {

  const cache = useCache();
  const { user } = useContext(AuthContext);
  useEffect(() => {
    if (user?.isEmailVerified && !cache?.isWarm) {
      void cache.warmup();
    }
  }, [user?.isEmailVerified])

  return (
    <AppLayout>
      <Suspense fallback={ <MgSpinner block /> }><MainRouter /></Suspense>
    </AppLayout>
  )
}