import { Field } from "components/base/MgForm/Field";
import { MgSelectBox } from "components/base/MgSelectBox";

export const SelectBoxField = (props) => {
  return (
    <Field { ...props }>
      { (fieldProps, helpers) => {
        return (
          <MgSelectBox
            { ...fieldProps }
            onChange={ (val) => helpers.setValue(val) }
          />
        )
      } }
    </Field>
  );
};
