import config from "mg.config";
import { ApiResponse } from "api/response";
import { $fetch } from "api/fetch";

export async function $refresh(token) {
  const url = `${ config.urls.ssoApi }/api/v2/auth/merchant/refresh/`;
  const params = { token };
  const opts = {
    method: "POST",
    headers: { "Accept": "application/json", "Content-Type": "application/json" },
    body: JSON.stringify(params),
  };
  try {
    return await new ApiResponse().fromResult(await $fetch(url, opts));
  } catch (error) {
    const err = { code: "ApiError", name: "API Error", message: error.toString() }
    return new ApiResponse().withError(err);
  }
}
