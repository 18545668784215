export function MgBox(
  {
    children,
    title = "",
    className = "",
    titleComponent,
  }
) {
  return (
    <section className={ className }>
      <div className="bg-white shadow rounded-md border border-gray-300">
        { (!!title || !!titleComponent) &&
        <div className="flex items-center px-4 py-2.5 font-semibold text-gray-500 rounded-t-md
                        text-xs uppercase border-b border-gray-200 bg-gray-50">
          { title }
          <div className="flex-grow" />
          { titleComponent }
        </div> }
        <div>
          { children }
        </div>
      </div>
    </section>
  )
}