import { Field } from "components/base/MgForm/Field";
import { MgSwitch } from "components/base/MgSwitch";

export const SwitchField = (props) => {
  return (
    <Field { ...props }>
      { (fieldProps, helpers) => {
        return (
          <MgSwitch
            value={ !!fieldProps.value }
            onChange={ (val) => helpers.setValue(!!val) }
            { ...props }
          />
        )
      } }
    </Field>
  );
};
