import { Field } from "components/base/MgForm/Field";
import { AntDatePicker } from "components/date-pickers/antd/AntDatePicker";
import moment from "moment";

export const DateTimeField = ({ pickerProps = {}, ...props }) => {
  return (
    <Field { ...props }>
      { (fieldProps, helpers) => {
        const val = moment.utc(fieldProps?.value);
        return (
          <>
            <AntDatePicker
              { ...fieldProps }
              value={ !!val.isValid() ? val : "" }
              onChange={ d => helpers.setValue(!!d ? d.format("YYYY-MM-DDTHH:mm:ss+0000") : "") }
              format="dddd, LL"
              width="100%"
              getPopupContainer={ triggerNode => triggerNode.parentNode }
              { ...pickerProps }
            />
          </>
        )
      } }
    </Field>
  );
};
