import { Field } from "components/base/MgForm/Field";
import { MgSelect } from "components/base/MgSelect";

export const SelectField = (props) => {
  return (
    <Field { ...props }>
      { (fieldProps, helpers) => {
        return (
          <MgSelect
            className="w-full"
            variant={ !!props.error ? "danger" : "default" }
            { ...fieldProps }
            onChange={ (val) => helpers.setValue(val) }
            // closeOnSelect={ !!!fieldProps.multiple }
          />
        )
      } }
    </Field>
  );
};
