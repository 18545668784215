import { createContext, useReducer } from "react";

const initialState = {
  loaded: false,
  endpoints: [],
  regions: [],
  paymentMethods: [],
  banks: [],
  marketRates: {},
};

const CacheContext = createContext(initialState);

const CacheProvider = ({ children }) => {
  const [state, dispatch] = useReducer((state, action) => {
    const s = { ...state };
    switch (action.type) {
      case "set":
        s.endpoints = action.data.endpoints || [];
        s.regions = action.data.regions || [];
        s.paymentMethods = action.data.paymentMethods || [];
        s.banks = action.data.banks || [];
        s.marketRates = action.data.marketRates || {};
        s.loaded = true;
        return s

      case "clear":
        s.endpoints = [];
        s.regions = [];
        s.paymentMethods = [];
        s.banks = [];
        s.marketRates = {};
        return s

      default:
        throw new Error(`unexpected action, ${ action.type }.`);
    }
  }, initialState);
  return <CacheContext.Provider value={ { dispatch, ...state } }>{ children }</CacheContext.Provider>
}

export { CacheContext, CacheProvider };