import { PageContext } from "contexts";
import { useContext, useEffect } from "react";

export function useGlobalReloader(reloadCb = () => {}) {
  const { addGlobalReloadHandler, removeGlobalReloadHandler } = useContext(PageContext);
  useEffect(() => {
    if (typeof reloadCb !== "function") {
      return;
    }
    addGlobalReloadHandler(reloadCb);
    return () => removeGlobalReloadHandler(reloadCb);
  }, []);
}