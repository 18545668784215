import React, { useContext, useRef, useState } from "react";
import { ToastContext } from "contexts";
import { XIcon } from "@heroicons/react/solid";
import { CheckCircleIcon } from "@heroicons/react/outline";
import { useTimeout } from "hooks/useTimeout";
import { Transition } from "@headlessui/react";
import { Link } from "react-router-dom";

export function Toast({ toast }) {

  const [isShown, setIsShown] = useState(true)
  const { removeToast } = useContext(ToastContext)
  const toastTimer = useRef([])
  const leaveDuration = 800

  // setting the show flag to false will trigger the leave-animation and when
  // the transition is finished our afterLeave callback will remove the toast
  toastTimer.current = useTimeout(() => {
    setIsShown(false)
  }, toast.timeout);

  const toastLeaveHandler = () => {
    removeToast(toast.id)
  }
  
  const ConditionalLink = ({ children, to, condition }) => (!!condition && to)
    ? <Link to={ to }>{ children }</Link>
    : <>{ children }</>;

  return (
    <Transition
      show={ isShown }
      appear={ true }
      enter="transition duration-400"
      enterFrom="transform translate-x-1/2 opacity-0"
      enterTo="transform translate-x-0 opacity-100"
      leave={ `transition duration-${ leaveDuration } ease-out` }
      leaveFrom="transform opacity-100 translate-x-0"
      leaveTo="transform opacity-0 translate-x-1/2"
      afterLeave={ toastLeaveHandler }
    >
      <div
        className="w-full bg-white shadow-lg rounded-lg pointer-events-auto ring-1 ring-black ring-opacity-5 overflow-hidden p-4 relative translate-x-0"
        style={ { width: 320 } }>

        <div className="flex items-center w-full">
          <div className="flex-shrink-0">
            <CheckCircleIcon className="h-6 w-6 text-green-400" aria-hidden="true" />
          </div>
          <ConditionalLink to={ toast.link } condition={ !!toast.link }>
            <div className="ml-3 flex-1 pt-0.5 text-sm text-gray-600">
              { typeof toast.children === "function" ? toast.children() : toast.children }
            </div>
          </ConditionalLink>
        </div>
        <button className="w-6 h-full flex flex-col align-center justify-center absolute right-2 top-0"
                onClick={ () => removeToast(toast.id) }>
          <XIcon className="text-gray-300 hover:text-gray-400 w-6 h-6 p-1.5" />
        </button>

      </div>
    </Transition>
  );
}





