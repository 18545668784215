import { Link } from "react-router-dom";


export function Mg401() {
  return (
    <div className="mainwidth mx-auto py-16 px-4 sm:py-24 sm:px-6 lg:px-8">
      <div className="text-center">
        <h2 className="text-base font-semibold text-indigo-600 tracking-wide uppercase">Permission Denied</h2>
        <p className="mt-1 text-4xl font-extrabold text-gray-900 sm:text-5xl sm:tracking-tight lg:text-6xl">
          401
        </p>
        <p className="max-w-xl mt-5 mx-auto text-xl text-gray-500">
          You're not authorized to enter this page.
        </p>
        <Link to="/" className="block mt-5 text-base font-semibold text-indigo-600">
          Return Home
        </Link>
      </div>
    </div>
  )
}