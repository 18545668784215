import { classNames } from "util/classUtil";
import config from "mg.config";
import React from "react";

export function MgTextarea(
  {
    inputRef = null,
    variant = "",
    value = "",
    type = "text",
    className = "",
    inputClassName = "",
    prepend,
    append,
    disabled = false,
    small = false,
    ...restProps
  }
) {

  return (
    <div className={ classNames(
      "flex rounded-md shadow-sm",
      disabled && "opacity-75",
      className
    ) }>
      { prepend && <div
        className={classNames(
          "inline-flex items-center px-3 rounded-l-md border border-r-0 border-gray-300 bg-gray-50 text-gray-500 text-xs",
          small ? "" : "sm:text-sm",
        )}>
        { prepend }
      </div> }
      <InputText
        { ...restProps }
        type={ type }
        ref={ inputRef }
        value={ value }
        disabled={ disabled }
        step="any"
        autoComplete="off"
        className={ classNames(
          "flex-1 min-w-0 block w-full px-3 border-gray-300 disabled:cursor-not-allowed disabled:bg-gray-50 rounded-md focus:z-10 transition-all",
          `focus:ring-${ config.primaryColor }-500 focus:border-${ config.primaryColor }-500`,
          prepend && "rounded-l-none",
          append && "rounded-r-none",
          small ? "py-1.5 text-xs pl-2.5" : "py-2 text-sm",
          inputClassName,
        ) }
      />
      { append && <div
        className={classNames(
          "inline-flex items-center rounded-r-md border border-l-0 border-gray-300 bg-gray-50 text-gray-500 text-xs",
          small ? "px-1" : "px-3 sm:text-sm",
        )}>
        { append }
      </div> }
    </div>
  )
}

const InputText = React.forwardRef((props, ref) => (
  <textarea ref={ ref } { ...props } />
));
