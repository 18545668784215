export class ApiResponse {

  constructor(objectType) {
    this.type = objectType;
    this.ok = false;
    this.data = {};
    this.object = null;
    this.objects = [];
    this.pagination = null;
    this.error = {};
  }

  async fromResult(rawResult) {
    const statusCode = rawResult?.status;
    if (!rawResult?.ok) {
      this.ok = false;
      if (statusCode === 401) {
        this.error = { code: "Unauthorized", name: "Access Denied", message: "Unauthorized session.", statusCode };
      } if (statusCode === 423) {
        this.error = { code: "Locked", name: "Access Denied", message: "Your account has been blocked. Please try again in 30 min", statusCode };
      } else {
        let message = `status ${ statusCode || 'N/A' }`;
        try {
          const errObj = await rawResult.json();
          const { error } = errObj;
          message = error || message;
        } catch (err) {
          console.log(err);
        }

        this.error = { code: "ApiError", name: "API Error", message, statusCode };
      }
      return this;
    }

    const jsonData = await rawResult.json();
    if (jsonData?.status === "OK") {

      const data = jsonData.data;
      this.data = data;
      this.ok = true;

      if (this.type) {
        if (data?.items && Array.isArray(data.items)) {
          for (let item of data.items) {
            this.objects.push(new this.type(item));
          }
        } else {
          this.object = new this.type(data);
        }
        this.pagination = data?.page || null;
      }
    } else {
      this.ok = false;
      this.error = jsonData?.error || {
        code: "ResponseError",
        name: "Response Error",
        message: `There was a problem processing your request.`,
        statusCode,
      };
    }
    return this;
  }

  withError(err) {
    this.ok = false;
    this.error = err;
    return this;
  }
}