import * as Yup from "yup";
import { Formik, Form } from 'formik';
import { TextField } from "components/base/MgForm/TextField";
import { SelectField } from "components/base/MgForm/SelectField";
import { SwitchField } from "components/base/MgForm/SwitchField";
import { DisplayField } from "components/base/MgForm/DisplayField";
import { CheckField } from "components/base/MgForm/CheckField";
import { SelectBoxField } from "components/base/MgForm/SelectBoxField";
import { TextareaField } from "components/base/MgForm/TextareaField";
import { DateTimeField } from "components/base/MgForm/DateTimeField";

export function MgForm(
  {
    children,
    schema, // Yup object.
    initialValues = {},
    onSubmit = (values, actions) => {
    },
  }
) {
  return (
    <Formik
      validationSchema={ schema || Yup.object({}) }
      initialValues={ initialValues }
      onSubmit={ onSubmit }
    >
      { (formik) => (
        <Form>
          { typeof children === "function" ? children(formik) : children }
        </Form>
      ) }
    </Formik>
  )
}

MgForm.TextField = TextField;
MgForm.TextareaField = TextareaField;
MgForm.SelectField = SelectField;
MgForm.SelectBoxField = SelectBoxField;
MgForm.CheckField = CheckField;
MgForm.SwitchField = SwitchField;
MgForm.DateTimeField = DateTimeField;
MgForm.DisplayField = DisplayField;