import { MgBox } from "components/base/MgBox";
import { MgSpinner } from "components/base/MgSpinner";
import { MgButton } from "components/base/MgButton";
import { classNames } from "util/classUtil";

export function MgGrowingListBox(
  {
    title = "",
    naked = false,
    ...props
  }
) {
  return naked ?
    <ListBox { ...props } /> :
    <MgBox title={ title }><ListBox { ...props } /></MgBox>;
}


const ListBox = (
  {
    items = [],
    itemContent = (item) => "",
    loading = false,
    hasMore = false,
    onMoreClick = () => {},
    itemClassName = "",
  }
) => (
  <div className="text-sm">
    { (!items || !items.length) && <div className="p-5 text-gray-400">
      No items.
    </div> }
    <div className="divide-y border-b overflow-y-auto max-h-[360px]">
      { items.map((item, itemIdx) => (
        <div className={ itemClassName || "px-5 py-3" } key={ itemIdx }>
          { itemContent(item) }
        </div>
      )) }
    </div>
    { (loading || hasMore) && <div className="text-center py-3 px-5">
      { loading ?
        <MgSpinner /> :
        <MgButton className="block w-full" onClick={ onMoreClick }>
          Load more
        </MgButton>
      }
    </div> }
  </div>
)