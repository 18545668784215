import { ClipboardCheckIcon, ClipboardCopyIcon } from "@heroicons/react/outline";
import { useRef, useState } from "react";
import { MgTooltip } from "components/base/MgTooltip";
import { classNames } from "util/classUtil";


export function MgClipboardLabel({ value = "", naked = false, truncate = false }) {

  const label = useRef(null);
  const [isCopied, setIsCopied] = useState(false);

  const selectAndCopy = (e) => {
    if (!label || isCopied) {
      return;
    }
    selectContents(label.current);
    document.execCommand('copy');
    setIsCopied(true);
    setTimeout(() => setIsCopied(false), 2000);
    e.stopPropagation();
    e.preventDefault();
  }

  if (!!truncate) {
    return <MgTooltip tooltip={value}><Inner /></MgTooltip>;
  }

  return <Inner />;

  function Inner (){
    return (
      <div className="flex items-center group">
        <div
          ref={ label }
          className={ classNames(
            naked ? "" : "text-sm text-gray-600 border border-gray-200 rounded p-1 px-2 bg-gray-50 italic",
            truncate ? "truncate w-[100px]":""
          )}
        >
          { value }
        </div>
        { isCopied ?
          <MgTooltip tooltip="Copied to clipboard" place="bottom">
            <ClipboardCheckIcon
              className="h-4 w-4 ml-1 text-indigo-600 cursor-default"
            />
          </MgTooltip> :
          <ClipboardCopyIcon
            onClick={ selectAndCopy }
            className="h-4 w-4 ml-1 text-gray-300 cursor-pointer opacity-0 group-hover:opacity-100 transition-opacity"
          /> }
      </div>
    )
  }
}

const selectContents = (el) => {
  let range = document.createRange();
  range.selectNodeContents(el);
  let sel = window.getSelection();
  sel.removeAllRanges();
  sel.addRange(range);
}