import { Menu, Transition } from "@headlessui/react";
import { Badge } from "components/common/Badge";
import { AnnotationIcon, BellIcon, ClipboardCheckIcon, ClipboardIcon } from "@heroicons/react/outline";
import { Fragment, useContext, useEffect, useRef, useState } from "react";
import { NotificationsContext } from "contexts";
import { MgSpinner } from "components/base/MgSpinner";
import { MgErrorBox } from "components/base/MgErrorBox";
import { MgGrowingListBox } from "components/base/MgGrowingListBox";
import { MgDatetime } from "components/base/MgDatetime";
import { Link, useLocation } from "react-router-dom";
import { classNames } from "util/classUtil";
import { useClickOutside } from "hooks";
import { DotsHorizontalIcon } from "@heroicons/react/solid";


export function NotificationsMenu() {
  const location = useLocation();
  const [isOpen, setIsOpen] = useState(false);
  const menuRef = useRef();
  const {
    totalUnread,
    notificationList,
    pushNotifications,
    loading,
    error
  } = useContext(NotificationsContext);

  useClickOutside(menuRef, () => setIsOpen(false));

  useEffect(() => {
    if (notificationList.loading || !notificationList.items.length) {
      void notificationList.loadFirstPage();
    }
  }, []);

  useEffect(() => setIsOpen(false), [location?.pathname]);

  return (
    <div>
      <Menu>
        <Menu.Button as="div" className="bg-white flex rounded-full text-gray-400 ">
          <div className="relative" onClick={ () => setIsOpen(!isOpen) }>
            {
              totalUnread > 0 &&
              <div className="absolute pointer-events-none right-[-6px] top-[-9px]">
                <Badge variant={ Badge.variant.RED } size={ Badge.size.SMALL }>
                  { totalUnread >= 5 ? '5+' : totalUnread }
                </Badge>
              </div>
            }
            <button
              className="bg-white rounded-full p-1 text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
              <BellIcon className="h-5 w-5" aria-hidden="true" />
            </button>
          </div>
        </Menu.Button>
        <Transition
          show={ isOpen }
          enter="transition duration-100 ease-out"
          enterFrom="transform scale-95 opacity-0"
          enterTo="transform scale-100 opacity-100"
          leave="transition duration-75 ease-out"
          leaveFrom="transform scale-100 opacity-100"
          leaveTo="transform scale-95 opacity-0"
        >
          <div ref={ menuRef }
               className="w-[250px] h-auto absolute right-0 origin-top-right bg-white divide-y divide-gray-100 rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
            { !!loading && <div className="text-center py-5"><MgSpinner /></div> }
            { !!error && <div className="mt-5"><MgErrorBox { ...error } /></div> }
            <div className="max-h-[610px] overflow-auto divide-y divide-gray-100">
              <div className="flex justify-between px-3 py-1 items-center bg-gray-50">
                <div className="text-gray-600 font-medium  text-xs">Notifications</div>
                <NotificationsActionMenu />
              </div>
              <div className="">
                <div className="flex justify-end p-3 pb-0">
                  {/*<div className="text-xs font-semibold text-gray-400 relative top-[3px]">Earlier</div>*/}
                  {/*<span className="p-1 text-indigo-500 cursor-pointer" onClick={()=> }>See All</span>*/ }
                  <Link to="/my-account/notifications/" className="block text-xs  text-indigo-500 w-ful text-right">
                    See All
                  </Link>
                </div>
                <NotificationsList
                  loading={ loading }
                  pushNotifications={ pushNotifications }
                  oldNotificationsList={ notificationList }
                  onClick={ () => setIsOpen(false) }
                />
              </div>
            </div>
          </div>
        </Transition>
      </Menu>
    </div>
  )
}

const NotificationsActionMenu = () => {
  // todo move to notification context
  const { markAllAsRead, markAllAsArchived } = useContext(NotificationsContext);
  return (
    <Menu as="div" className="relative inline-block text-left z-15">
      { ({ open }) => (
        <>
          <div>
            <Menu.Button
              className="flex items-center rounded-md focus:bg-opacity-0 group">
              <div
                className="w-6 h-auto text-gray-600 cursor-pointer rounded-full text-gray-600 group-hover:text-indigo-600 p-1 transition-all">
                <DotsHorizontalIcon /></div>
            </Menu.Button>
          </div>

          <Transition
            show={ open }
            as={ Fragment }
            enter="transition ease-out duration-100"
            enterFrom="transform opacity-0 scale-95"
            enterTo="transform opacity-100 scale-100"
            leave="transition ease-in duration-75"
            leaveFrom="transform opacity-100 scale-100"
            leaveTo="transform opacity-0 scale-95"
          >
            <Menu.Items
              static
              className="origin-top-right absolute -right-2 mt-1 w-[240px] rounded-sm shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none divide-y divide-gray-100 z-30"
            >

              <Menu.Item>
                { ({ active }) => (

                  <span
                    onClick={ () => markAllAsRead() }
                    className={ classNames(
                      active ? 'bg-indigo-50 text-gray-900' : 'text-gray-700',
                      'flex w-full space-x-2 px-2 py-1.5 text-sm items-center cursor-pointer'
                    ) }
                  >
                      <div className="w-3 h-auto"><ClipboardIcon /></div>
                      <span>Mark all as read</span>
                    </span>
                ) }
              </Menu.Item>
              <Menu.Item>
                { ({ active }) => (
                  <span
                    onClick={ () => markAllAsArchived() }
                    className={ classNames(
                      active ? 'bg-indigo-50 text-gray-900' : 'text-gray-700',
                      'flex w-full space-x-2 px-1.5  py-1.5 text-sm items-center cursor-pointer'
                    ) }
                  >
                    <div className="w-3 h-auto"><ClipboardCheckIcon /></div>
                    <span>Clear all</span>
                    </span>
                ) }
              </Menu.Item>
            </Menu.Items>
          </Transition>
        </>
      ) }
    </Menu>
  )
}

function NotificationsList({ pushNotifications = [], oldNotificationsList, loading = false, onClick }) {

  const notifications = [...pushNotifications, ...(oldNotificationsList.items || [])].sort((a, b) => b.id - a.id);

  return <MgGrowingListBox
    items={ notifications }
    itemContent={ (item) => <NotificationItem { ...item } onClick={ onClick } /> }
    loading={ loading }
    hasMore={ oldNotificationsList.hasNext }
    onMoreClick={ oldNotificationsList.loadNextPage }
    itemClassName="py-1"
    naked
  />;
}

function NotificationItem({ id, link, isRead, message, createdAt, onClick = () => {} }) {

  const { readNotification } = useContext(NotificationsContext);
  const onBulletClick = (e) => {
    e.stopPropagation();
    e.preventDefault();
    readNotification(id);
  }

  const onItemClick = () => {
    readNotification(id);
    onClick();
  }

  return (
    <a href={ link } onClick={ onItemClick } className="group-hover:bg-gray-200">
      <div className="px-2 py-1.5">
        <div className="flex items-center relative">
          <AnnotationIcon className="h-6 w-6 text-gray-400 group-hover:text-gray-500" aria-hidden="true" />
          <div className="ml-3">
            <div className={ classNames("text-xs mr-6", isRead ? "font-normal" : "font-bold") }>
              { message }
            </div>
            <div
              onClick={ onBulletClick }
              className={ classNames(
                "h-3 w-3 rounded-full absolute right-[12px] top-[50%] -translate-y-1/2 transition-all shadow-sm z-20",
                isRead ? "bg-gray-400" : "bg-indigo-500"
              ) } />
            <span className="text-xs text-gray-500"><MgDatetime value={ createdAt } lowerCase={ true } /></span>
          </div>
        </div>
      </div>
    </a>
  )
}