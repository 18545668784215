import moment from "moment";

export function timeAgo(dt) {
  try {
    return moment(dt).fromNow();
  } catch (err) {
    return "";
  }
}

export function formatUtcTime(d) {
  const ret = moment(d).utc().format();
  if (ret.endsWith("Z")) {
    return ret.replace("Z", "+0000");
  }
  return ret;
}