import { TopBar } from "components/layout/TopBar";
import { AppFooter } from "components/layout/AppFooter";
import { NotificationsProvider, PageProvider } from "contexts";
import { useLocation } from "react-router-dom";
import { useEffect, useRef } from "react";


export function AppLayout({ children }) {

  // scroll to top on route change.
  const location = useLocation();
  useEffect(() => window.scrollTo(0, 0), [location?.pathname]);

  // global reload handler.
  const reloadHandlers = useRef([]);
  const addGlobalReloadHandler = cb => reloadHandlers.current.push(cb);
  const removeGlobalReloadHandler = cb => reloadHandlers.current = reloadHandlers.current.filter(h => h !== cb);
  const onGlobalReload = () => {
    console.log(`#### GLOBAL RELOAD: ${ reloadHandlers.current.length } callbacks registered.`)
    for (const cb of reloadHandlers.current) {
      cb();
    }
  }

  return (
    <div className="min-h-screen flex flex-col body-bg-color overflow-hidden md:overflow-auto">
      {/* fixed top bar */ }
      <NotificationsProvider>
        <TopBar onReloadClick={ onGlobalReload } />
      </NotificationsProvider>

      {/* compensators for fixed header */ }
      <div className="hidden md:block h-36" style={ { height: 110 } } />
      <div className="block md:hidden" style={ { height: 80 } } />

      {/* main content */ }
      <main
        style={ { minHeight: "60vh" } }
        className="mainwidth mx-auto pb-10 px-4 md:pt-5 md:pb-12 md:px-8 w-full flex-1"
      >
        <PageProvider
          addGlobalReloadHandler={ addGlobalReloadHandler }
          removeGlobalReloadHandler={ removeGlobalReloadHandler }
        >
          { children }
        </PageProvider>
      </main>
      <AppFooter />
    </div>
  )
}
