import { Fragment, useContext } from 'react'
import { Menu, Transition } from '@headlessui/react'
import { classNames } from "util/classUtil";
import { Link, useHistory } from "react-router-dom";
import { UserSymbol } from "components/symbols/UserSymbol";
import { AuthContext } from "contexts";
import { MgSpinner } from "components/base/MgSpinner";

export function UserHandle() {
  const history = useHistory();
  const { user, logout } = useContext(AuthContext);

  if (!user) {
    return <MgSpinner block />;
  }

  return (
    <Menu as="div" className="relative inline-block text-left">
      { ({ open }) => (
        <>
          <div>
            <Menu.Button
              className="flex items-center rounded-md focus:bg-opacity-0 group">
              <UserSymbol
                user={ user }
                size={ 8 }
                className="cursor-pointer md:w-8 md:h-8 focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
              />
            </Menu.Button>
          </div>

          <Transition
            show={ open }
            as={ Fragment }
            enter="transition ease-out duration-100"
            enterFrom="transform opacity-0 scale-95"
            enterTo="transform opacity-100 scale-100"
            leave="transition ease-in duration-75"
            leaveFrom="transform opacity-100 scale-100"
            leaveTo="transform opacity-0 scale-95"
          >
            <Menu.Items
              static
              className="origin-top-right absolute right-4 mt-2 w-64 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none divide-y divide-gray-100"
            >
              <div className="py-2">
                <div className="flex items-center px-3 py-3">
                  <UserSymbol className="mr-2" user={ user } />
                  <div className="flex flex-col">
                    <div className="flex flex-row items-center">
                      <div className="text-sm font-medium text-gray-700 leading-5">
                        { user.firstName } { user.lastName }
                      </div>
                    </div>
                    <div
                      className="flex flex-row text-gray-500 leading-4 text-xs font-normal">
                      { user.title || user.email }
                    </div>
                  </div>
                </div>
                <Menu.Item>
                  { ({ active }) => (
                    <Link
                      to={ `/my-account/` }
                      className={ classNames(
                        active ? 'bg-indigo-50 text-gray-900' : 'text-gray-700',
                        'block w-full text-left px-4 py-1.5 text-sm'
                      ) }
                    >
                      My Account
                    </Link>
                  ) }
                </Menu.Item>
                { !!user?.isRoot && <Menu.Item>
                  { ({ active }) => (
                    <Link
                      to={ `/admin/users/` }
                      className={ classNames(
                        active ? 'bg-indigo-50 text-gray-900' : 'text-gray-700',
                        'block w-full text-left px-4 py-1.5 text-sm'
                      ) }
                    >
                      User Management
                    </Link>
                  ) }
                </Menu.Item> }
                <Menu.Item>
                  { ({ active }) => (
                    <div
                      onClick={ () => {
                        logout();
                        history.push("/");
                      } }
                      className={ classNames(
                        active ? 'bg-indigo-50 text-gray-900' : 'text-gray-700',
                        'block w-full text-left px-4 py-1.5 text-sm cursor-pointer'
                      ) }
                    >
                      Sign out
                    </div>
                  ) }
                </Menu.Item>
              </div>
            </Menu.Items>
          </Transition>
        </>
      ) }
    </Menu>
  )
}

