export function MgLogo(props) {

  const size = props?.size?.toString().replace(/px/g, '') || 17;

  return (
    <span
      {...props}>
      <svg
        version="1.0"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 1820 1820"
        preserveAspectRatio="xMidYMid meet"
        className="w-auto mx-auto"
        style={ { height: `${size}px` } }>
        <g stroke="none" fill="currentColor">
          <path
            d="M832 1783 l-62 -35 2 -436 3 -436 43 -29 c39 -26 119 -69 337 -181 44 -23 112 -60 152 -84 39 -23 78 -42 86 -42 15 0 43 -27 36 -34 -2 -2 -44 -24 -93 -50 l-89 -46 -32 20 c-18 11 -37 20 -43 20 -6 0 -50 23 -99 51 -48 28 -106 59 -128 69 -22 9 -75 38 -118 64 -43 25 -82 46 -87 46 -6 0 -134 70 -152 83 -1 1 -5 201 -8 444 l-5 441 -95 -47 -95 -47 -3 -415 c-1 -228 0 -427 3 -444 5 -26 20 -38 113 -90 59 -33 116 -63 127 -66 11 -4 60 -29 108 -58 49 -28 93 -51 99 -51 5 0 51 -25 102 -55 50 -30 97 -55 104 -55 32 0 3 -23 -83 -66 -73 -36 -98 -45 -107 -36 -7 7 -18 12 -26 12 -8 0 -40 15 -71 34 -31 19 -103 59 -161 89 -251 130 -301 157 -344 182 l-46 27 0 444 c0 244 -3 444 -7 444 -5 0 -50 -21 -100 -47 l-93 -48 0 -442 c0 -442 0 -442 22 -463 12 -11 28 -20 35 -20 8 0 57 -25 109 -55 53 -30 100 -55 106 -55 5 0 37 -16 71 -37 61 -36 164 -91 342 -182 50 -26 111 -59 136 -74 l47 -27 76 45 c42 25 81 45 87 45 6 0 37 15 68 34 31 19 100 57 154 85 196 103 283 149 345 185 35 20 68 36 73 36 8 0 37 16 117 67 23 14 23 15 5 28 -19 13 -133 74 -300 161 -57 30 -121 65 -143 79 -22 14 -44 25 -50 25 -6 0 -43 18 -83 41 -78 44 -97 54 -170 90 -93 46 -87 21 -87 355 l0 294 28 -6 c15 -4 45 -18 66 -32 38 -24 136 -75 355 -189 57 -29 110 -58 118 -64 9 -8 13 -40 13 -110 0 -55 -2 -99 -5 -99 -2 0 -26 14 -52 30 -26 17 -53 30 -60 30 -7 0 -50 22 -95 49 -80 47 -151 81 -169 81 -11 0 -12 -170 -1 -186 10 -15 117 -74 134 -74 9 0 26 -9 40 -20 14 -10 81 -48 149 -83 68 -36 149 -79 179 -96 30 -17 58 -31 63 -31 4 0 7 135 7 299 l0 299 -77 45 c-43 25 -91 51 -106 56 -16 6 -66 34 -113 61 -47 28 -89 50 -95 50 -5 0 -52 25 -103 55 -51 30 -105 59 -120 65 -14 5 -70 35 -122 65 -53 30 -96 55 -97 54 -1 0 -30 -16 -65 -36z" />
        </g>
      </svg>
    </span>
  )
}