import { Link } from "react-router-dom";
import { MgLogo } from "components/base/MgLogo";


const nav = {
  gateway: [
    { name: 'Dashboard', path: '/home/' },
    { name: 'Orders', path: '/orders/' },
    { name: 'Merchant Account', path: '/account/' },
  ],
  utils: [
    { name: 'Payout Batch Uploader', path: '/payout-manager/upload/' },
    { name: 'Single Payouts Terminal', path: '/payout-manager/terminal/' },
  ],
  reports: [
    { name: 'Orders Report', path: '/reports/legacy/orders-report/' },
    { name: 'Daily Reports', path: '/balance/reports/daily/' },
  ],
}

export function AppFooter() {
  return (
    <footer style={ { background: "rgb(45,38,66)" } } aria-labelledby="footerHeading">
      <div className="mainwidth mx-auto py-12 px-4 sm:px-6 lg:py-16 lg:px-8">
        <div className="xl:grid xl:grid-cols-3 xl:gap-8">
          <div className="grid grid-cols-2 gap-8 xl:col-span-2">
            <div className="md:grid md:grid-cols-2 md:gap-8">
              <div>
                <h3 className="text-sm font-semibold text-gray-400 tracking-wider uppercase">Payment Gateway</h3>
                <ul className="mt-4 space-y-2">
                  { nav.gateway.map((item) => (
                    <li key={ item.name }>
                      <Link to={ item.path } className="text-base text-gray-300 hover:text-white">
                        { item.name }
                      </Link>
                    </li>
                  )) }
                </ul>
              </div>
            </div>
            <div className="md:grid md:grid-cols-2 md:gap-8">
              <div>
                <h3 className="text-sm font-semibold text-gray-400 tracking-wider uppercase">Reports</h3>
                <ul className="mt-4 space-y-2">
                  { nav.reports.map((item) => (
                    <li key={ item.name }>
                      <Link to={ item.path } className="text-base text-gray-300 hover:text-white">
                        { item.name }
                      </Link>
                    </li>
                  )) }
                </ul>
              </div>
              <div className="mt-12 md:mt-0">
                <h3 className="text-sm font-semibold text-gray-400 tracking-wider uppercase">Utilities</h3>
                <ul className="mt-4 space-y-2">
                  { nav.utils.map((item) => (
                    <li key={ item.name }>
                      <Link to={ item.path } className="text-base text-gray-300 hover:text-white">
                        { item.name }
                      </Link>
                    </li>
                  )) }
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className="mt-8 border-t border-gray-600 pt-8 md:flex md:items-center md:justify-between">
          <div className="flex space-x-6 md:order-2">
            <MgLogo size={ 20 } className="text-indigo-400 transition-colors" />
          </div>
          <p className="mt-8 text-base text-gray-400 md:mt-0 md:order-1">
            &copy; { (new Date()).getFullYear() } Metagate v2.0 by Zota Technologies Ltd. All rights reserved.
          </p>
        </div>
      </div>
    </footer>
  )
}