import { useApi } from "hooks/useApi";
import { useState } from "react";

export function useServerGrid(
  {
    // - either: -
    type,
    resourceType = "model",
    // - or: -
    apiPath,
    apiData = {},
    //
    filters = [],
    allowMultiSort = false,
  }
) {
  const api = useApi();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  if (!!type && !type?.type) {
    throw new Error(`useServerGrid: invalid type argument.`);
  }

  const baseFilters = parseFilters(filters);

  const getRows = async grid => {
    setLoading(true);
    setError(null);

    const gridRequest = grid.request;
    const params = generateRequest(gridRequest, baseFilters);
    if (!!type) {
      const res = await api.$filter(type, params, resourceType);
      setLoading(false);
      if (res.ok) {
        const rows = res.objects || [];
        const totalRows = res.pagination?.totalItems || 0;
        grid.success({
          rowData: rows,
          rowCount: totalRows,
        })
      } else {
        setError(res.error);
        grid.fail();
      }
      return;
    }

    if (!apiPath) {
      return;
    }

    const path = apiPath;
    const data = { ...params, ...apiData };
    const res = await api.$merchant({ path, data });
    setLoading(false);
    if (res.ok) {
      const rows = res.data.items || [];
      const totalRows = res.data.pagination?.totalItems || 0;
      grid.success({
        rowData: rows,
        rowCount: totalRows,
      })
    } else {
      setError(res.error);
      grid.fail();
    }
  }

  return {
    loading,
    error,
    getRows,
  }
}

const generateRequest = (gridRequest, baseFilters = []) => {

  let ret = {};
  const { startRow, endRow, filterModel, sortModel } = gridRequest;

  // filters.
  const gridFilters = convertGridFiltersToFilters(filterModel);
  const filters = [...baseFilters, ...gridFilters];
  ret = { ...ret, filters };

  // pagination.
  if (startRow >= 0 && endRow > startRow) {
    const pageSize = endRow - startRow;
    const pageNum = parseInt(startRow / pageSize) + 1;
    const page = { pageSize, pageNum };
    ret = { ...ret, page };
  }

  // sort.
  // sortModel: [{sort: 'asc', colId: 'id'}, ...]
  let sortColumns = sortModel.map(s => ({ sortBy: s.colId, sortDirection: s.sort }));
  const orderBy = { sortColumns };
  ret = { ...ret, orderBy };

  return ret;
}

const parseFilters = (filters = []) => {
  let ret = [];
  if (Array.isArray(filters)) {
    ret = filters;
  } else if (typeof filters === "object" && Object.keys(filters || {}).length > 0) {
    ret = convertArgsToFilters(filters || {});
  } else if (!filters || !Array.isArray(filters)) {
    ret = [];
  }
  for (let f of ret) {
    if (!isFilterValid(f)) {
      throw new Error(`invalid list filter, ${ JSON.stringify(f) }`)
    }
  }
  return ret;
}

const convertGridFiltersToFilters = (filterModel) => {
  // filterModel: {name: {filterType: "text", type: "contains", filter: "foo"}}
  let ret = [];
  for (const field in filterModel) {
    const f = filterModel[field];
    const isMultiCondition = !!f.operator && !!f.condition1;
    if (isMultiCondition) {
      // [{filterType: 'text', type: 'contains', filter: 'foo'}, ...]
      const conds = Object.keys(f).filter(k => k.indexOf("condition") === 0).map(k => f[k]);
      for (const cond of conds) {
        ret.push(convertGridFilter(field, cond));
      }
      continue;
    }
    ret.push(convertGridFilter(field, f));
  }
  return ret;
}

const convertGridFilter = (property, f) => {
  const condition = f.filterType === "set" ? "in" : formatCondition(f.type);
  const value = getValue(f);
  return {
    property,
    condition,
    value,
  }
};

const convertArgsToFilters = (args = {}) => Object.keys(args).map(arg => ({
  property: arg,
  condition: "equals",
  value: args[arg]
}));

const formatCondition = c => {
  switch (c) {
    case "inRange":
      return "between";
    case "greaterThan":
      return "moreThan";
    case "greaterThanOrEqual":
      return "moreThanOrEquals";
    case "lessThanOrEqual":
      return "lessThanOrEquals";
    default:
      return c;
  }
}

const getValue = f => {
  if (f.filterType === "set") {
    return f.values || [];
  }
  if (f.filterType === "number" && f.type === "inRange") {
    return [f.filter, f.filterTo];
  }
  return f.filter;
}

const isFilterValid = (f = {}) => !!(typeof f === "object" && !!f.property);