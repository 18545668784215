import { useState } from "react";
import { useApi } from "hooks";

export function useMerchantBalance(merchantId) {

  const api = useApi();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [data, setData] = useState(null);
  const [isEnabled,setIsEnabled]=useState(false);

  /*
  summary: {
    accounts: [{…}]
    allowedBalanceUsagePercent: "90"
    allowedOverdraft: {currency: 'USD', amount: '0', isEstimated: false}
    baseCurrency: "USD"
    merchantID: "NIKTO"
    totalAuthorized: {currency: 'USD', amount: '0', isEstimated: false}
    totalBalance: {currency: 'USD', amount: '0', isEstimated: false}
    totalPayoutBalance: {currency: 'USD', amount: '0', isEstimated: false}
  }
   */

  const load = async (silent = false) => {
    if (!merchantId || !!loading) {
      return;
    }
    if (!silent) {
      setLoading(true);
    }
    setError(null);
    const path = "accounting/balance-summary/";
    const data = { merchantID: merchantId };
    const params = { path, data };
    const res = await api.$merchant(params);
    if (res.ok) {
      if (res.data.balanceEnabled) {
        setData(res.data.summary);
        setIsEnabled(true);
      }
    } else {
      setError(res.error);
    }
    if (!silent) {
      setLoading(false);
    }
    return !!res.ok;
  }

  const loaded = !loading && !!data;

  return {
    error,
    loading,
    loaded,
    isEnabled,
    data,
    load,
  };
}