import { createContext, useContext, useEffect } from "react";
import { AuthContext } from "contexts/AuthContext";
import { useMerchantBalance } from "components/balance/merchant/useMerchantBalance";


const BalanceContext = createContext();

const BalanceProvider = ({ children }) => {
  const { userMid } = useContext(AuthContext);
  const balance = useMerchantBalance(userMid);
  useEffect(() => !!userMid && balance.load(), [userMid]);

  return <BalanceContext.Provider value={ { balance } }>
    { children }
  </BalanceContext.Provider>
}

export { BalanceContext, BalanceProvider };