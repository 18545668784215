import { createContext, useContext } from "react";
import { MerchantUser } from "MgTypes";
import { AuthContext, ModelViewProvider } from "contexts";
import { MailIcon } from "@heroicons/react/solid";
import { KeyIcon, CogIcon, IdentificationIcon } from "@heroicons/react/outline";


const UserContext = createContext(null);

const UserProvider = ({ id, children }) => {

  const { user } = useContext(AuthContext);
  const { isRoot } = user;

  const navItems = (inst) => [
    {
      name: "User Accounts",
      path: `/admin/users/`,
      isBack: true,
    },
    {
      name: "User Overview",
      icon: IdentificationIcon,
      path: `/admin/users/${ id }/overview/`,
    },
    {
      name: "Settings",
      icon: CogIcon,
      path: `/admin/users/${ id }/settings/`,
      hide: !isRoot,
    },
    {
      name: "Login Options",
      icon: KeyIcon,
      path: `/admin/users/${ id }/authentification/`,
      hide: !isRoot,
    },
  ];

  return (
    <ModelViewProvider
      context={ UserContext }
      type={ MerchantUser }
      params={ { userId: id } }
      navItems={ navItems }
      titleStats={ titleStats }
      typeDisplayName="User Account"
      breadcrumbs={ (user) => [
        { name: "Administration", path: "/admin/" },
        { name: "User Accounts", path: `/admin/users/` },
        {
          name: `${ user.firstName } ${ user.lastName }`,
          path: `/admin/users/${ id }/`
        },
      ] }
    >
      { children }
    </ModelViewProvider>
  )
}

const titleStats = (inst) => [
  {
    text: `${ inst.firstName } ${ inst.lastName }`,
    icon: IdentificationIcon
  },
  {
    text: inst.email,
    icon: MailIcon
  },
];

export { UserContext, UserProvider };