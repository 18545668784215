import numeral from "numeral";

export function toPascalCase(s) {
  if (!s) {
    return s;
  }
  return s.replace(/-/g, " ").replace(/_/g, " ").replace(/(\w)(\w*)/g, function (g0, g1, g2) {
    return g1.toUpperCase() + g2.toLowerCase();
  }).replace(/ /g, "");
}

export function toSentence(s, titleCase = true, removeIdSuffix = false) {
  if (!s) {
    return s;
  }
  if (!!removeIdSuffix && s.toLowerCase() !== "id") {
    if (!!s.endsWith("ID")) {
      s = s.substring(0, s.length - 2);
    } else if (!!s.endsWith("Id")) {
      s = s.substring(0, s.length - 2);
    } else if (!!s.endsWith("_id")) {
      s = s.substring(0, s.length - 3);
    }
  }
  const ret = s.replace(/([A-Z]+)/g, " $1").replace(/([A-Z][a-z])/g, " $1").replace(/-/g, " ").replace(/__/g, " ").replace(/_/g, " ").trim();
  return titleCase ? toTitleCase(ret) : ret;
}

export function toTitleCase(s) {
  return s ? s.replace(
    /\w\S*/g,
    function (txt) {
      return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    }
  ) : "";
}

export function getInitials(s, n = 2, upper = true) {

  if (s.length < n) {
    n = s.length;
  }

  // split by space.
  let ret = s.split(" ").map((n) => n[0]).join("");
  if (ret.length >= n) {
    ret = ret.substring(0, n);
    return upper ? ret.toUpperCase() : ret.toLowerCase();
  }

  if (ret.length < n) {
    // split by case.
    ret = (s.match(/[A-Z][a-z]+/g) || []).map((n) => n[0]).join("");
    if (ret.length >= n) {
      ret = ret.substring(0, n);
      return upper ? ret.toUpperCase() : ret.toLowerCase();
    }

    // no split.
    ret = s.substring(0, n);
    return upper ? ret.toUpperCase() : ret.toLowerCase();
  }

  return upper ? ret.toUpperCase() : ret.toLowerCase();
}

export function isUpper(s) {
  return s === s.toUpperCase();
}

export function shortNum(n = 0) {
  try {

    n = Math.round(n);
    let fmt = parseInt(n) <= 999 ? "0a" : "0.0a";
    let ret = numeral(n).format(fmt).toUpperCase();
    ret = ret
      .replace('.0K', 'K')
      .replace('.0M', 'M');

    if (ret.endsWith('.0')) {
      ret = ret.substring(0, ret.length - 2);
    }

    return ret

  } catch (err) {
    return 0;
  }
}

export function camelize(str) {
  return str.replace(/(?:^\w|[A-Z]|\b\w)/g, function (word, index) {
    return index === 0 ? word.toLowerCase() : word.toUpperCase();
  }).replace(/\s+/g, '');
}

export function decamelize(s) {
  const sep = "_";
  return s
    .replace(/([a-z\d])([A-Z])/g, '$1' + sep + '$2')
    .replace(/([A-Z]+)([A-Z][a-z\d]+)/g, '$1' + sep + '$2')
    .toLowerCase();
}

export function pluralize(word = "", amount = 0, pluralWord = "") {
  if (amount === 1) {
    return word;
  }
  return pluralWord ? pluralWord : (word.toLowerCase().endsWith("y") ? `${ word.substring(0, word.length - 1) }ies` : `${ word }s`);
}

export function splitCamelCase(s) {
  if (!s) {
    return s;
  }
  let words = s.match(/[A-Z0-9][a-z0-9]+/g);
  if (words.length < 2) {
    return s;
  }
  return words.join(" ");
}

export const validateEmail = email => {
  const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
}