const storageKeyName = "mgTablePrefs";

export function useTableColumnPreferences(modelType) {

  const typeName = modelType?.type;

  const store = (colIds) => {
    if (!typeName || !Array.isArray(colIds)) {
      return;
    }
    const dataStr = localStorage.getItem(storageKeyName);
    if (!!dataStr) {
      try {
        const data = JSON.parse(dataStr);
        const newData = { ...data, [typeName]: colIds };
        localStorage.setItem(storageKeyName, JSON.stringify(newData));
        return;
      } catch (err) {}
    }

    localStorage.setItem(storageKeyName, JSON.stringify({ [typeName]: colIds }));
  };

  const load = () => {
    if (!typeName) {
      return null;
    }
    const dataStr = localStorage.getItem(storageKeyName);
    if (!!dataStr) {
      try {
        const data = JSON.parse(dataStr);
        if (data && data[typeName] && Array.isArray(data[typeName])) {
          return data[typeName];
        }
      } catch (err) {}
    }
    return null;
  };

  const clear =() => {
    if (!typeName) {
      return;
    }
    const dataStr = localStorage.getItem(storageKeyName);
    if (!!dataStr) {
      try {
        const data = JSON.parse(dataStr);
        if (data && data[typeName]) {
          delete data[typeName];
          localStorage.setItem(storageKeyName, JSON.stringify(data));
        }
      } catch (err) {}
    }
  }

  return { store, load, clear };
}