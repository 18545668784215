import { useField } from "formik";
import { classNames } from "util/classUtil";

export function Field(
  {
    children,
    label = "",
    error = null,
    className = "",
    hideLabels = false,
    bottomDescription = false,
    labelAction,
    hideLabelAction = false,
    persistLabelAction = false,
    ...props
  }
) {

  // useField() returns [formik.getFieldProps(), formik.getFieldMeta()]
  // which we can spread on <input>. We can use field meta to show an error
  // message if the field is invalid and it has been touched (i.e. visited)
  const [field, meta, helpers] = useField(props);
  const fieldProps = { ...field, ...props };

  return (
    <div className={ className }>
      <div className="flex flex-col w-full relative group">
        <div className="flex flex-row justify-between">
          { !!!hideLabels && <div className="flex flex-col">
            <label
              htmlFor={ props.name }
              className="block font-medium text-14px text-color-strong mb-1"
            >
              { label }
            </label>
            { !!props.description && !bottomDescription &&
            <div className="text-sm text-gray-400 mb-2 -mt-0.5">{ props.description }</div> }
          </div> }
          { !!labelAction && !hideLabelAction && <div className="overflow-auto">
            <div
              className={classNames(
                "absolute right-0 top-0 text-xs pr-1 w-full text-right text-indigo-600",
                persistLabelAction ? "" : "opacity-0 group-hover:opacity-100 transition-opacity",
                hideLabels ? "-top-5 pb-1" : "top-0",
              )}
              style={ { minWidth: 50 } }
            >
              <div className="cursor-pointer">
                { typeof labelAction === "function" ? labelAction(helpers) : labelAction }
              </div>
            </div>
          </div> }
        </div>

        { typeof children === "function" ? children(fieldProps, helpers) : children }

        { !!props.description && bottomDescription &&
        <div className="text-sm text-gray-400 mt-1">{ props.description }</div> }

        { !!(meta.touched && meta.error) && (
          <div className="mt-1.5 text-sm text-red-400">{ meta.error }</div>
        ) }
      </div>
    </div>
  )
}