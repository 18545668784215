import config from "mg.config";
import { useContext } from "react";
import { AlertsContext, AuthContext } from "contexts";
import { $fetch } from "api/fetch";
import { ApiResponse } from "api";
import { usePermissions } from "hooks/usePermissions";
import { toSentence } from "util/textUtil";
import { useLocation } from "react-router-dom";


export function useApi() {

  const { pathname } = useLocation();
  const { user, userMid, refresh, refreshIfNeeded, getCsrfToken } = useContext(AuthContext);
  const { canView, canExecute } = usePermissions();
  const alerts = useContext(AlertsContext);

  const headers = {
    "Accept": "application/json",
    "Content-Type": "application/json",
    "X-CSRFToken": getCsrfToken(),
    "MgPath": pathname
  };

  const $get = async (type, params = {}) => {
    return $do("get", type, params);
  }

  const $filter = async (type, params = {}, resourceType = "model") => {
    const actionType = resourceType === "view" ? "filter/view" : "filter";
    return $do(actionType, type, params);
  }

  const $loadView = async (type, params = {}) => {
    return $do("view", type, params);
  }

  const $execute = async (type, params = {}) => {
    return $do("exec", type, params);
  }

  const $do = async (actionType, modelType, params = {}) => {
    if (["get", "filter", "view", "filter/view", "exec"].indexOf(actionType) === -1) {
      throw new Error(`$do: unknown query type, ${ actionType }.`)
    }
    if (!user) {
      throw new Error(`$${ actionType }: called before user was initialized.`);
    }
    const typeName = modelType?.type;
    if (!typeName) {
      throw new Error(`$${ actionType }: invalid type argument.`);
    }

    let isPermitted = false;
    if (actionType === "exec") {
      isPermitted = canExecute(modelType);
    } else {
      isPermitted = canView(modelType);
    }

    if (!isPermitted) {
      console.error(`$${ actionType }: ${ typeName }: Permission denied.`);
      const err = {
        code: "Unauthorized",
        name: "Permission denied",
        message: `You're not authorized for "${ toSentence(typeName) }".`
      }
      return new ApiResponse(modelType).withError(err);
    }

    const url = `${ config.urls.officeApi }/api/v2/m/${ userMid }/objects/${ actionType }/${ typeName }/`;
    const opts = {
      method: "POST",
      headers,
      body: JSON.stringify(params),
    };

    return await httpRequest(modelType, url, opts);
  }

  const $getPolicy = async (type, resourceType = "model", listId = undefined) => {
    const typeName = type?.type;
    if (!typeName) {
      throw new Error(`$getPolicy: invalid type argument.`);
    }
    const actionType = resourceType === "view" ? "filter/view" : "filter";
    const url = `${ config.urls.officeApi }/api/v2/m/${ userMid }/objects/${ actionType }/${ typeName }/policy/`;
    const opts = { method: "POST", headers };

    if (!!listId) {
      opts.body = JSON.stringify({ id: listId });
    }

    return await httpRequest(null, url, opts);
  }

  const $uploadFile = async (
    {
      file,
      storageName = "",
    }
  ) => {

    if (!file || !storageName) {
      throw new Error(`$uploadFile: missing arguments.`);
    }

    const fd = new FormData();
    fd.append("file", file);

    const url = `${ config.urls.officeApi }/api/v2/m/${ userMid }/upload/${ storageName }/`;
    const opts = {
      method: "POST",
      body: fd,
      headers: { "X-CSRFToken": getCsrfToken() },
    };

    return await httpRequest(null, url, opts);
  }

  const $merchant = async ({ path, data = {}, method = "POST" }) => {
    const url = `${ config.urls.merchantApi }/api/v1/${ path }`;
    const body = JSON.stringify(data);
    const opts = { method, headers, body };
    return await httpRequest(null, url, opts);
  }

  const httpRequest = async (type, url, opts) => {

    await refreshIfNeeded();

    try {

      const res = await new ApiResponse(type).fromResult(await $fetch(url, opts));
      if (res.ok) {
        return res;
      }

      if (res.error?.statusCode === 401) {
        // try to refresh - if unsuccessful - redirect to login page.
        const refreshed = await refresh();
        if (refreshed === true) {
          if (alerts) {
            alerts.clearAlerts("error");
          }
          return await httpRequest(type, url, opts);
        } else {
          return res;
        }
      } else if (res.error?.code === "NotFound") {
        res.error.name = `Oops ...`;
        res.error.message = res.error.message ||
          `Requested ${ (toSentence(type?.type) || "resource").toLowerCase() } was not found.`;
        return res;
      } else {
        return res;
      }

    } catch (error) {
      const err = { code: "ApiError", name: "API Error", message: error.toString() }
      return new ApiResponse(type).withError(err);
    }
  }

  return {
    $execute,
    $get,
    $filter,
    $loadView,
    $uploadFile,
    $getPolicy,
    $merchant,
  }
}