import { NativeSelect } from "components/base/MgSelect/NativeSelect";
import { RichSelect } from "components/base/MgSelect/RichSelect";

export function MgSelect(
  {
      selectRef,
      value,
      items = [],
      itemsLimit = -1,
      multiple = false,
      closeOnSelect = true,
      maxHeight = 180,
      disabled = false,
      placeholder,
      onChange = (val) => {},
      small = false,
      xsmall = false,
      native = false,
      naked = false,
      clearable = false,
      className = "",
      style = {},
      filterOption,
      fixedItemOrder = false,
      blurInputOnSelect = false,
      dataTestId = "",
  }
) {
    return (
      native ?
        <NativeSelect
          selectRef={ selectRef }
          options={ items }
          value={ value }
          disabled={ disabled }
          onChange={ onChange }
          placeholder={ placeholder }
          small={ small }
          xsmall={ xsmall }
          naked={ naked }
          clearable={ clearable }
          className={ className }
          style={ style }
        /> :
        <RichSelect
          itemsLimit={itemsLimit}
          selectRef={ selectRef }
          value={ value }
          items={ items }
          multiple={ multiple }
          closeOnSelect={ closeOnSelect }
          maxHeight={ maxHeight }
          disabled={ disabled }
          placeholder={ placeholder }
          onChange={ onChange }
          small={ small }
          xsmall={ xsmall }
          naked={ naked }
          clearable={ clearable }
          filterOption={ filterOption }
          className={ className }
          style={ style }
          fixedItemOrder={ fixedItemOrder }
          blurInputOnSelect={ blurInputOnSelect }
          dataTestId={ dataTestId }
        />
    )
}

