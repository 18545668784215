import { Field } from "components/base/MgForm/Field";
import { MgClipboardLabel } from "components/base/MgClipboardLabel";

export const DisplayField = (props) => {
  return (
    <Field { ...props }>
      { (fieldProps) => {
        const val = fieldProps.value;
        return <MgClipboardLabel value={ typeof val === "object" ? JSON.stringify(val,null,2) : val } />;
      } }
    </Field>
  );
};
