import React, { useState, createContext } from "react";
import { v4 as uuidv4 } from 'uuid';
import { Toast } from "components/common/Toast";

export const ToastContext = createContext({});

export function ToastContextProvider({ children }) {

  const [toasts, setToasts] = useState([]);

  const addToast = (children, timeout = 6000, link = null) => {
    const id = uuidv4();
    setToasts(toasts =>
      [...toasts,
        { id, children, timeout, link}
      ]
    );
  }
  const removeToast = (id) => {
    setToasts([...toasts.filter(t => t.id !== id)]);
  }
  return (
    <ToastContext.Provider value={ {
      addToast,
      removeToast
    } }>
      { children }
      { toasts.length > 0 &&
      <div className="fixed inset-0 flex items-end px-4 py-6 pointer-events-none sm:p-6 sm:items-start z-30  ">
        <div className="w-full flex flex-col items-center space-y-4 sm:items-end relative z-4">
          { toasts.map((toast) =>
            <Toast key={ toast.id } toast={ toast } />
          ) }
        </div>
      </div>
      }
    </ToastContext.Provider>
  );
}
