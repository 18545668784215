import Select, { components, } from "react-select";
import { SelectorIcon, XIcon } from "@heroicons/react/solid";
import React, { useState } from "react";
import { classNames } from "util/classUtil";

;

export function RichSelect(
  {
    openMenuOnFocus = false,
    selectRef,
    value,
    items = [],
    itemsLimit = -1,
    multiple = false,
    closeOnSelect = true,
    maxHeight = 180,
    disabled = false,
    naked = false,
    small = false,
    xsmall = false,
    placeholder,
    clearable = false,
    onChange = (val) => {},
    filterOption,
    fixedItemOrder = false,
    blurInputOnSelect = false,
    dataTestId = ""
  }
) {

  let val = multiple ?
    items.filter((item) => (value || []).indexOf(item.value) !== -1) :
    items.filter((item) => value === item.value);

  val = fixedItemOrder ? val.sort((a, b) => (value || []).indexOf(a.value) - (value || []).indexOf(b.value)) : val;

  const onSelectChange = (selectedValue) => {
    if (selectedValue === null) {
      const val = multiple ? [] : null;
      onChange(val);
      return;
    }
    const val = multiple ? selectedValue.map(({ value }) => value) : selectedValue.value;
    onChange(val);
  }

  const DropdownIndicator = props => {
    return (
      <components.DropdownIndicator { ...props }>
        <SelectorIcon className="text-gray-400 h-4 w-4" aria-hidden="true" />
      </components.DropdownIndicator>
    )
  };

  const Menu = props => {
    return (
      <components.Menu { ...props }>
        <span { ...(dataTestId ? { "data-testid": `${dataTestId}Dropdown` } : {}) }>
          {props.children}
        </span>
      </components.Menu>
    )
  }

  const Input = props => {
    if (props.isHidden) {
      return <components.Input {...props} />;
    }
    return (
      <div { ...(dataTestId ? { "data-testid": `${dataTestId}` } : {}) }>
          <components.Input {...props} />
      </div>
    );
  }

  const ClearIndicator = props => {
    return (
      <components.ClearIndicator { ...props }>
        <XIcon className="mr-0 text-gray-300 hover:text-gray-500 transition-colors h-3 w-3" />
      </components.ClearIndicator>
    )
  }


  const [displayAllItems, setDisplayAllItems] = useState(false)
  const LimitedChipsContainer = ({ children, hasValue, getValue, ...props }) => {

    const [chips, otherChildren] = children;
    const overflowCounter = getValue().length;
    const displayChips = !!chips.length && chips.slice(0, itemsLimit);
    const title = items.map(i => i.value).join(", ");
    if (itemsLimit === -1 || !itemsLimit) {
      return components.ValueContainer
    }

    if (!hasValue) {
      return (
        <components.ValueContainer { ...props }>


          { displayChips }
          { otherChildren }
        </components.ValueContainer>
      );
    }

    return (
      <components.ValueContainer { ...props }>

        { displayChips }

        { overflowCounter > itemsLimit &&
        <span>
          <div className="text-white bg-indigo-500 px-2 text-xs py-1 rounded">
            <div title={ title }>

              { ` + ${ overflowCounter - itemsLimit } items` }
            </div>
          </div>
        </span>
        }

        { otherChildren }
      </components.ValueContainer>
    );
  };


  const selectComponents = () => {

    //LimitedChipsContainer

    const comps = {
      ClearIndicator,
      DropdownIndicator,
      Menu,
      Input
    }
    if (!displayAllItems && multiple && itemsLimit !== -1) {
      return { ...comps, ValueContainer: LimitedChipsContainer }
    }
    return { ...comps }
  }

  return (
    <div className={ classNames(
      "relative w-full flex items-center group",
      !!disabled ? "opacity-75" : "",
    ) }>
      <Select
        openMenuOnFocus={ {
          ClearIndicator,
          DropdownIndicator,
          ValueContainer: LimitedChipsContainer
        } }
        blurInputOnSelect={ blurInputOnSelect }
        ref={ selectRef }
        options={ items }
        components={ selectComponents() }
        isMulti={ multiple }
        closeMenuOnSelect={ closeOnSelect }
        styles={ getStyles(maxHeight, small, xsmall, disabled, naked) }
        className="mg-select text-sm group flex-1"
        value={ val }
        placeholder={ <span style={ { paddingLeft: 3 } }>{ placeholder || "Select ..." }</span> }
        isDisabled={ disabled }
        onChange={ onSelectChange }
        filterOption={ filterOption }
        autoFocus={ false }
        autofocus={ false }
      />
      { !!clearable && !!value && !disabled &&
      <div
        onClick={ () => onSelectChange(multiple ? [] : null) }
        className="absolute right-0 mr-[26px] cursor-pointer"
      >
        <XIcon className="text-gray-300 hover:text-gray-500 transition-colors h-3 w-3" />
      </div> }
    </div>
  )
}

/*
var createFilter = function createFilter(config) {
  return function (option, rawInput) {
    var _ignoreCase$ignoreAcc = _objectSpread2({
      ignoreCase: true,
      ignoreAccents: true,
      stringify: defaultStringify,
      trim: true,
      matchFrom: 'any'
    }, config),
        ignoreCase = _ignoreCase$ignoreAcc.ignoreCase,
        ignoreAccents = _ignoreCase$ignoreAcc.ignoreAccents,
        stringify = _ignoreCase$ignoreAcc.stringify,
        trim = _ignoreCase$ignoreAcc.trim,
        matchFrom = _ignoreCase$ignoreAcc.matchFrom;

    var input = trim ? trimString(rawInput) : rawInput;
    var candidate = trim ? trimString(stringify(option)) : stringify(option);

    if (ignoreCase) {
      input = input.toLowerCase();
      candidate = candidate.toLowerCase();
    }

    if (ignoreAccents) {
      input = memoizedStripDiacriticsForInput(input);
      candidate = stripDiacritics(candidate);
    }

    return matchFrom === 'start' ? candidate.substr(0, input.length) === input : candidate.indexOf(input) > -1;
  };
};
 */


const getStyles = (maxHeight, small, xsmall, disabled, naked) => {
  const controlHeight = 25; // small ? 25 : 35;
  const controlFontSize = xsmall ? 10 : small ? 11 : 13;
  const smallOrXsmall = !!small || !!xsmall;
  return {
    control: (base, state) => {
      return {
        ...base,
        transition: "all .2s ease",
        minHeight: controlHeight,
        fontSize: controlFontSize,
        paddingLeft: 0,
        paddingRight: 0,
        minWidth: naked ? "none" : 80,
        cursor: disabled ? "not-allowed" : "pointer",
        border: naked ? "none" : (state.menuIsOpen ? "solid 1px #6366F1" : "solid 1px rgb(209, 213, 219)"),
        boxShadow: naked ? "none" : (state.menuIsOpen ? "0 0 0 1px #6366F1" : "0 1px 2px 0 rgba(0, 0, 0, 0.05)"),
        backgroundColor: naked ? "transparent" : "#fff",
        "&:hover": {
          border: naked ? "none" : (state.menuIsOpen ? "solid 1px #6366F1" : "solid 1px rgb(209, 213, 219)"),
        },
      }
    },
    valueContainer: (provided, state) => ({
      ...provided,
      height: 'auto',
      justifyContent: naked ? "center" : "start",
      fontWeight: naked ? 500 : "normal",
      padding: naked ? "none" : (xsmall ? '0px 4px' : small ? '2px 4px' : '2px 5px 2px 6px')
    }),
    singleValue: (provided) => {
      if (naked) {
        return {
          ...provided,
          position: "relative",
          top: 10,
          padding: naked ? "0 2px" : provided.padding,
          textOverflow: naked ? "unset" : "ellipsis",
          color: naked ? "#6b7280" : provided.color,
        }
      }
      return provided;
    },
    multiValue: (provided) => ({
      ...provided,
      borderRadius: '8px',
      margin: '3px 5px',
      padding: '0px 2px',
      ...(smallOrXsmall &&
        {
          height: 'auto',
          padding: '0px',
          minHeight: '10px',
          borderRadius: '6px',
          paddingRight: '2px',
          margin: '3px',
        })
    }),
    multiValueLabel: (provided) => ({
      ...provided,

      ...(smallOrXsmall && {
        padding: '0px 6px',
        minHeight: '6px',
        paddingRight: '0px',
        marginTop: '1px',
      })
    }),
    multiValueRemove: (provided) => ({
      ...provided,
      ...(smallOrXsmall && {
        width: '15px',
        height: '15px',
        padding: '2px',
        marginRight: '1px',
        marginTop: '2px'
      })
    }),
    input: (provided) => ({
      ...provided,
      margin: '0px',
    }),
    indicatorSeparator: () => ({}),
    indicatorsContainer: (provided) => ({
      ...provided,
      height: 'auto',
      paddingTop: 0,
      paddingBottom: 0,
      display: naked ? "none" : "flex",
    }),
    dropdownIndicator: (provided) => ({
      ...provided,
      paddingTop: 0,
      paddingBottom: 0,
    }),
    menu: (provided) => ({
      ...provided,
      minWidth: 80,
    }),
    menuList: (base) => {
      return {
        ...base,
        maxHeight: maxHeight,
        boxShadow: "0 3px 10px rgba(0,0,0,0.05)",
        padding: 0
      }
    },
    option: (base, { data, isDisabled, isFocused, isSelected }) => {
      return {
        ...base,
        cursor: "pointer",
        height: "auto",
        minHeight: 0,
        padding: xsmall ? "4px 10px" : small ? "6px 12px" : "10px 12px",
        fontWeight: 400,
        fontSize: xsmall ? 10 : small ? 11 : 12,
        background: isDisabled ? '#fff' : isFocused ? "rgba(0,0,0,0.025)" : "none",
        color: isDisabled ? '#D1D5DB' : isFocused ? "#1F2937" : "#374151",
        "&:active": {
          background: isDisabled ? '#fff' : isFocused ? "rgba(0,0,0,0.025)" : "none",
          color: isDisabled ? '#D1D5DB' : isFocused ? "#1F2937" : "#374151",
        }
      }
    }
  }
};




