import React from "react";
import config from "mg.config";
import { classNames } from "util/classUtil";

export function MgButton(
  {
    children,
    type = "button",
    variant = "default",
    size = "default",
    disabled = false,
    className = "",
    PrependIcon = null,
    AppendIcon = null,
    iconCls,
    onClick = () => {},
    ...props
  }) {
  return (
    <button
      type={ type }
      className={ classNames(
        `inline-flex items-center justify-center font-medium rounded focus:outline-none focus:ring-2 focus:ring-offset-2 disabled:opacity-40 disabled:cursor-not-allowed whitespace-nowrap transition-opacity group`,
        getCls(variant, size),
        className,
      ) }
      disabled={ !!disabled }
      onClick={ onClick }
      { ...props }
    >
      { !!PrependIcon &&
      <PrependIcon
        className={ classNames(
          getIconCls(variant, size),
          !!children && "mr-1 -ml-1",
          iconCls,
        ) }
        style={ { height: 18 } }
        aria-hidden="true"
      /> }
      { children }
      { !!AppendIcon &&
      <AppendIcon
        className={ classNames(
          getIconCls(variant, size),
          !!children ? "ml-1 -mr-1" : "",
          iconCls,
        ) }
        style={ { height: 18 } }
        aria-hidden="true"
      /> }
    </button>
  )
}

const variants = {
  default: `border border-gray-300 shadow-sm text-gray-700 bg-white hover:bg-gray-50 focus:ring-${ config.primaryColor }-500 transition-all`,
  primary: `border border-transparent shadow-sm text-white bg-${ config.primaryColor }-500 hover:bg-${ config.primaryColor }-600 focus:ring-${ config.primaryColor }-500 transition-all`,
  secondary: `border border-transparent text-${ config.primaryColor }-700 bg-${ config.primaryColor }-100 hover:bg-${ config.primaryColor }-200 focus:ring-${ config.primaryColor }-500 transition-all`,
  silent: `border border-transparent hover:border-gray-300 active:bg-white focus:ring-${ config.primaryColor }-500 transition-all`,
  danger: `border border-transparent shadow-sm text-white bg-red-600 hover:bg-red-700 focus:ring-red-500 transition-all`,
};
const sizes = {
  xsmall: "text-12px px-1.5 py-0.5",
  small: "text-12px px-2 py-1",
  default: "text-12px px-2 py-1 sm:text-12px sm:px-2.5 sm:py-1.5",
  large: "text-sm px-4 py-2",
  xlarge: "text-base px-6 py-3",
};

function getCls(variant, size) {
  return `${ variants[variant] } ${ sizes[size] }`;
}

const iconVariants = {
  default: `text-gray-500`,
  primary: `text-gray-200 group-hover:text-white`,
  secondary: `text-${ config.primaryColor }-600`,
  danger: `text-gray-100`,
};

const iconSizes = {
  xsmall: "w-3 h-3",
  small: "w-3 h-3",
  default: "w-3 h-3 sm:w-4 sm:h-4",
  large: "w-5 h-5",
  xlarge: "w-5 h-5",
};

function getIconCls(variant, size) {
  return `${ iconVariants[variant] } ${ iconSizes[size] }`;
}