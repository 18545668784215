import { getInitials } from "util/textUtil";
import { classNames } from "util/classUtil";

export function UserSymbol({ user, size = 8, className, bgColor = "indigo" }) {
  const sizeCls = `w-${ size } h-${ size }`;
  return (
    <>
      { !!user.picUrl ? <img className={ classNames(
          "rounded-full",
          sizeCls,
          className,
        ) } src={ user.picUrl } alt="" /> :
        <div
          className={ classNames(
            `rounded-full inline-flex items-center relative justify-center shadow-sm bg-${ bgColor }-500`,
            sizeCls,
            className,
          ) }>
          <div className="text-base text-gray-100">
            { getInitials(`${ user.firstName } ${ user.lastName }`) }
          </div>
        </div> }
    </>
  )
}

const dontRemove = ["bg-indigo-500", "bg-blue-500", "bg-green-500", "bg-yellow-500", "bg-red-500", "bg-teal-500"];