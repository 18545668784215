import { Link } from "react-router-dom";
import { Popover } from "@headlessui/react";
import { CodeIcon, MenuAlt2Icon, RefreshIcon } from "@heroicons/react/outline";
import { classNames } from "util/classUtil";
import { useContext, } from "react";
import { usePermissions } from "hooks";
import { MainMenuDesktop } from "components/layout/MainMenuDesktop";
import { AuthContext } from "contexts";
import { NotificationsMenu } from "components/common/NotificationsMenu";
import { UserHandle } from "components/layout/UserHandle";
import { mainMenuItems } from "main-menu/mainMenuItems";
import { MainMenuMobile } from "components/layout/MainMenuMobile";


export function TopBar(
  {
    onReloadClick = () => {},
  }
) {
  const { user, userMid, merchant, enableRegionScoping} = useContext(AuthContext);
  const permissions = usePermissions();
  const menuItems = mainMenuItems(user, merchant, permissions, enableRegionScoping).filter(x => !!x.show());

  return (
    <Popover as="header" className="bg-white shadow fixed left-0 z-30 w-full">
      { ({ open }) => (
        <>
          <div className="mainwidth mx-auto px-2 sm:px-4 lg:divide-y lg:divide-gray-200 lg:px-8">
            <div className="relative h-16 flex justify-between">
              <div className="relative z-10 px-0 flex">
                <div className="flex-shrink-0 flex items-center font-medium text-xl">
                  <Popover.Button
                    className="md:hidden rounded-md px-2 py-2 mr-1 inline-flex items-center justify-center text-indigo-500 hover:bg-gray-100">
                    <span className="sr-only">Open menu</span>
                    <MenuAlt2Icon className={
                      classNames(
                        "block h-6 w-6",
                        open && "text-indigo-500"
                      ) } aria-hidden="true" />
                  </Popover.Button>
                  <Logo />
                </div>
              </div>
              <div className="relative z-10 ml-3 flex items-center">
                {/*<div className="text-xs text-gray-400 mr-3">{ user.email }</div>*/}
                {/*<RecentItemsMenu />*/ }
                <button
                  onClick={ onReloadClick }
                  className="flex items-center p-1 rounded-full text-gray-400 focus:bg-opacity-0 group focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 cursor-pointer"
                >
                  <RefreshIcon className="h-5 w-5" aria-hidden="true" />
                </button>
                <div className="flex items-center relative ml-3">
                  <NotificationsMenu />
                </div>
                <div className="ml-3 flex items-center">
                  <UserHandle />
                </div>
              </div>
            </div>
            <MainMenuDesktop menuItems={ menuItems } />
          </div>
          <MainMenuMobile open={ open } menuItems={ menuItems } />
        </>
      ) }
    </Popover>
  )
}

const Logo = () => {
  const { env, merchant } = useContext(AuthContext);
  const name = merchant?.name || "Zotapay";
  const getText = () => {
    switch ((env || "").toLowerCase()) {
      case "dev":
        return <>
          <CodeIcon className="w-6 h-6 mr-2 text-gray-400" />
          { name } Development
        </>;
      case "stage":
        return <>
          <CodeIcon className="w-6 h-6 mr-2 text-gray-400" />
          { name } Staging
        </>;
      case "sandbox":
        return <>
          <CodeIcon className="w-6 h-6 mr-2 text-gray-400" />
          { name } Sandbox
        </>;
      default:
        return name;
    }
  }
  return (
    <Link to="/"
          className="flex items-center rounded-md text-gray-700 group focus:ring-2 focus:ring-inset focus:ring-indigo-500 px-2 py-2 -ml-2 leading-none opacity-90 focus:opacity-100 hover:opacity-100 transition-opacity">
      {/*<div style={{fontFamily:"Helvetica", fontSize:36}} className="mr-1.5 text-indigo-500 font-normal">
      ⌀
    </div>*/ }
      <div className="relative text-colorStrong-700 text-xl flex items-center">
        { getText() }
      </div>
    </Link>
  )
}
