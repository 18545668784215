import { classNames } from "util/classUtil";
import config from "mg.config";
import React, { useEffect } from "react";

export function MgTextbox(
  {
    inputRef = null,
    variant = "",
    value = "",
    type = "text",
    className = "",
    inputClassName = "",
    prepend,
    append,
    disabled = false,
    small = false,
    selectOnFocus = true,
    dataTestId = "",
    ...restProps
  }
) {

  useEffect(() => {
    document.addEventListener("wheel", onWheel);
    return () => document.removeEventListener("wheel", onWheel);
  }, []);

  const onWheel = () => {
    if (document.activeElement.type === "number") {
      document.activeElement.blur();
    }
  }

  const extraAttrs = () => {
    let ret = {};
    if (!!selectOnFocus) {
      ret["onFocus"] = e => e.target.select();
    }
    return ret;
  }

  return (
    <div className={ classNames(
      "flex rounded-md shadow-sm",
      disabled && "opacity-75",
      className
    ) }>
      { prepend && <div
        className={ classNames(
          "inline-flex items-center px-3 rounded-l-md border border-r-0 border-gray-300 bg-gray-50 text-xs text-gray-500",
          small ? "px-2" : "sm:text-sm",
        ) }>
        { prepend }
      </div> }
      <InputText
        { ...restProps }
        type={ type }
        ref={ inputRef }
        value={ value }
        disabled={ disabled }
        step="any"
        autoComplete="off"
        {...(dataTestId ? { 'data-testid': dataTestId } : {})}
        className={ classNames(
          "flex-1 min-w-0 block w-full px-3 border-gray-300 disabled:cursor-not-allowed disabled:bg-gray-50 rounded-md focus:z-10 transition-all",
          `focus:ring-${ config.primaryColor }-500 focus:border-${ config.primaryColor }-500`,
          prepend ? "rounded-l-none" : "",
          append ? "rounded-r-none" : "",
          disabled ? "text-gray-500" : "text-gray-700",
          small ? "py-1.5 text-xs pl-2.5" : "py-2 text-sm",
          inputClassName,
        ) }
        { ...extraAttrs() }
      />
      { append && <div
        className={ classNames(
          "inline-flex items-center rounded-r-md border border-l-0 border-gray-300 bg-gray-50 text-gray-500 text-xs",
          small ? "px-2" : "px-3 sm:text-sm",
        ) }>
        { append }
      </div> }
    </div>
  )
}

const InputText = React.forwardRef((props, ref) => (
  <input ref={ ref } { ...props } />
));
