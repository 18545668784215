import { Link, useHistory, useLocation } from "react-router-dom";
import { Fragment, useContext } from "react";
import { AuthContext } from "contexts";
import { Popover, Transition } from "@headlessui/react";
import { MgLogo } from "components/base/MgLogo";
import { XIcon } from "@heroicons/react/outline";
import { UserSymbol } from "components/symbols/UserSymbol";
import { NotificationsMenu } from "components/common/NotificationsMenu";
import { classNames } from "util/classUtil";


export function MainMenuMobile({ open = false, menuItems = [] }) {
  const history = useHistory();
  const location = useLocation();
  const { user, logout } = useContext(AuthContext);
  const userMenuItems = [
    {
      content: <Link to={ `/my-account/` } className="py-2 block">My Account</Link>
    },
    {
      content: <div
        onClick={ () => {
          logout();
          history.push("/");
        } }
        className="cursor-pointer py-2"
      >
        Logout
      </div>
    }
  ];

  const currentNavItem = menuItems.find(item => location.pathname.startsWith(item.path));

  return (
    <Transition.Root show={ open } as={ Fragment }>
      <div className="lg:hidden">
        <Transition.Child
          as={ Fragment }
          enter="duration-150 ease-out"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="duration-150 ease-in"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <Popover.Overlay static className="z-20 fixed inset-0 bg-black bg-opacity-25" />
        </Transition.Child>

        <Transition.Child
          as={ Fragment }
          enter="duration-150 ease-out"
          enterFrom="opacity-0 scale-95"
          enterTo="opacity-100 scale-100"
          leave="duration-150 ease-in"
          leaveFrom="opacity-100 scale-100"
          leaveTo="opacity-0 scale-95"
        >
          <Popover.Panel
            focus
            static
            className="z-30 absolute top-0 inset-x-0 max-w-3xl mx-auto w-full p-2 transition transform origin-top"
          >
            <div
              className="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 bg-white divide-y divide-gray-200">
              <div className="pt-3 pb-2">
                <div className="flex items-center justify-between px-4">
                  <Popover.Button as="div">
                    <Link to="/">
                      <MgLogo size={ 26 }
                              className="text-indigo-600 hover:text-indigo-500 transition-colors" />
                    </Link>
                  </Popover.Button>
                  <div className="-mr-2">
                    <Popover.Button
                      className="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500">
                      <span className="sr-only">Close menu</span>
                      <XIcon className="h-6 w-6" aria-hidden="true" />
                    </Popover.Button>
                  </div>
                </div>
                <div className="mt-3 px-2 space-y-1">
                  { menuItems.map((item) => (
                    <Popover.Button as="div" key={ item.name }>
                      <Link
                        to={ item.path }
                        className={classNames(
                          "block rounded-md px-3 py-2 text-base font-medium",
                          item === currentNavItem ? "text-indigo-600" : "text-gray-900 hover:bg-gray-100 hover:text-gray-800",
                        )}
                      >
                        { item.name }
                      </Link>
                    </Popover.Button>
                  )) }
                </div>
              </div>
              <div className="pt-4 pb-2">
                <div className="flex items-center px-5">
                  <div className="flex-shrink-0">
                    <UserSymbol user={ user } size={ 10 } />
                  </div>
                  <div className="ml-3 min-w-0 flex-1">
                    <div
                      className="text-base font-medium text-gray-800 truncate">{ user.firstName + " " + user.lastName }</div>
                    <div className="text-sm font-medium text-gray-500 truncate">{ user.email }</div>
                  </div>
                  <NotificationsMenu />
                </div>
                <div className="mt-3 px-2 space-y-1">
                  { userMenuItems.map((item, itemIdx) => (
                    <Popover.Button
                      as="div"
                      key={ itemIdx }
                      className="block rounded-md px-3 text-base text-gray-900 font-medium hover:bg-gray-100 hover:text-gray-800"
                    >
                      { item.content }
                    </Popover.Button>
                  )) }
                </div>
              </div>
            </div>
          </Popover.Panel>
        </Transition.Child>
      </div>
    </Transition.Root>
  )
}

