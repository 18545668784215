import moment from "moment";
import { MgTooltip } from "components/base/MgTooltip";
import { timeAgo } from "util/timeUtil";


export function MgDatetime({ value, lowerCase = false, format = "", utc=false }) {

  if (!value || !moment(value).isValid() || moment(value).year() < 1900) {
    // invalid date.
    return "-";
  }

  if (!!format) {
    if (!!utc) {
      return moment(value).utc().format(format);
    }
    return moment(value).format(format);
  }

  const ago = timeAgo(value);
  const label = timeAgo ? ago : moment.utc(value).calendar((null, {
    sameDay: '[Today at] H:mm',
    nextDay: '[Tomorrow at] H:mm',
    nextWeek: 'dddd',
    lastDay: '[Yesterday at] H:mm',
    lastWeek: '[Last] dddd',
    sameElse: `[${ ago }]`,
  }));

  let tooltip = `${ moment.utc(value).format("YYYY-MM-DD HH:mm:ss") } UTC\n (${ ago })`;
  return <MgTooltip tooltip={ tooltip } place="bottom">
    { lowerCase ? label.toLowerCase() : label }
  </MgTooltip>;
}
