import { Link, useLocation } from "react-router-dom";
import { classNames } from "util/classUtil";

export function MainMenuDesktop({ menuItems = [] }) {
  const location = useLocation();
  return (
    <nav className="hidden md:py-2 md:flex md:space-x-8" aria-label="Global">
      { menuItems.map((item) => {
        const active = location.pathname.startsWith(item.path);
        return (
          <Link
            key={ item.name }
            to={ item.path }
            className={ classNames(
              "py-2 inline-flex items-center text-sm font-medium transition-colors",
              active ? "text-indigo-600 hover:text-indigo-600" : "text-gray-600 hover:text-gray-900",
            ) }
          >
            { item.name }
          </Link>
        )
      }) }
    </nav>
  )
}

